

import { Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { clientsTable } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 480,
}));

// ---------------------------------------

const ClientsTable = ({ expandedState, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([]);

    // -------------------------------------------------------------------------------------

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
        if (isLargeDesktop) {
            return '20px';
        }
        if (isDesktop) {
            return '16px';
        }
        if (isTablet) {
            return '14px';
        }
        return '12px';
    };

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    // ----------------------------------------------------------------------------------------------------

    const transformData = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
    
        const transformedData = [];
        const keys = Object.keys(data[0]);
    
        
        const groupedData = {};
    
        keys.forEach((key) => {
            
            const pattern = key.match(/[^a-zA-Z0-9](.*?)$/); 
            if (pattern) {
                const dynamicPattern = pattern[1];
                if (!groupedData[dynamicPattern]) {
                    groupedData[dynamicPattern] = {};
                }
                const baseKey = key.split('.')[0];
                groupedData[dynamicPattern][baseKey] = data[0][key][0];
            } else {
                
                console.warn(`Key without identifiable dynamic pattern: ${key}`);
            }
        });
    
        
        Object.keys(groupedData).forEach((pattern) => {
            transformedData.push(groupedData[pattern]);
        });
    
        return transformedData;
    };

  

const fetchData = useCallback(() => {
    const data = getStartEnd();
    const payload = {
        start: data.start / 1000,
        end: data.end / 1000,
        step: data.step,
        device_type: healthDashState.selectedDevice?.l_dvc_typ_name,
    };
    setLoading(true);
    // Replace with actual API call
    // clientsTable(customerId, healthDashState.selectedDevice?.r_dvc_serial_number, payload)
    //     .then((res) => {
            setLoading(false);
            const clientTableData = allData?.client_table || [];
            const transformedData = transformData(clientTableData);
            setResData(transformedData);
            if (transformedData.length > 0) {
                const headers = Object.keys(transformedData[0]).map((key) => ({
                    headerName: key,
                    field: key,
                    width: 180,
                    sortable: true,
                    filter: true,
                    cellStyle: responsiveCellStyle,
                    // Add a valueFormatter to replace null or empty values with "NA"
                    valueFormatter: (params) => {
                        const value = params.value;
                        return value === null || value === '' ? 'NA' : value;
                    },
                }));
                setColumnHeaders(headers);
            }
    // })
    // .catch(() => {
    //     setLoading(false);
    // });
}, [healthDashState.selectedDevice, expandedState, customerId, getStartEnd, allData]);


    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState, fetchData]);

  

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    // -----------------------------------------------------------------------------------------------

    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={resData}
                            columnDefs={columnHeaders}
                            defaultColDef={defaultColDef}
                            rowHeight={50}  // Set row height
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};

export default ClientsTable;
