// import 'chonky/style/main.css';
import {
  Box,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  ChonkyActions,
  // FileArray,
  FileBrowser,
  // FileData,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import FolderIcon from '@mui/icons-material/Folder';
import { parse, format } from 'date-fns';
import path from 'path-browserify';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { BUCKET_NAME, fetchS3BucketContents } from '../../../API/S3/index';
import { viewBackUpDetails, viewBackUpFolderDetails, getdiffCurrPrev, getconfigBackupEventsviaSidebar } from '../../../API/S3/ConfigEvents';
import { getLocations } from '../../../API/Locations/showLocations'
import { UserContext } from '../../../context/UserContext';
import CMDBDialog from '../CMDBDialog';


setChonkyDefaults({ iconComponent: ChonkyIconFA });

// --------------------------------------------------------------------------
const useNavigateParams = () => {
  const navigate = useNavigate();

  return (url, params) => {
    const path = generatePath(':url', {
      url,
      queryString: params,
    });
    navigate(path);
  };
};
// ----------------------------------------------------------------------

export const ChonkyExpForTable = (props) => {
  const { filePath, data } = props;
  const [error, setError] = useState(null);
  const { customerId } = useContext(UserContext);
  const [folderPrefix, setKeyPrefix] = useState('');
  const [files, setFiles] = useState([]);
  const [readFileDialog, setReadFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [details, setDetails] = useState([])
  const navigate = useNavigateParams();
  const [backupTrue, setBackupTrue] = useState(false)
  const [locationBackup, setLocationBackup] = useState(false)
  const [customerBackup, setCustomerBackup] = useState(false)
  const [selectedFileDetails, setSelectedFileDetails] = useState([])
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeFolder, setActiveFolder] = React.useState(null); 

  const uniqueIdentifier = data?.r_dvc_unique_identifier
  useEffect(() => {
    setLoading(true);
    viewBackUpDetails(customerId, data?.r_dvc_unique_identifier)
      .then((res) => {
        setLoading(false);

        const parseSizeToBytes = (sizeStr) => {
          if (!sizeStr) return 0;
          const [value, unit] = sizeStr.split(' ');
          const numValue = parseFloat(value);

          switch (unit) {
            case 'KB':
              return numValue * 1024;
            case 'MB':
              return numValue * 1024 * 1024;
            case 'GB':
              return numValue * 1024 * 1024 * 1024;
            default:
              return numValue;
          }
        };


        //   const formattedFiles = res.map(file => ({

        //   id: file.file_name,
        //   name: file.file_name,  
        //   modDate: file.updated_at,
        //   size: file.golden_config_file_size ? parseSizeToBytes(file.golden_config_file_size) : 'Unknown size',
        //   content: file.golden_config || 'No config available',
        //   deviceName: file.device_name,
        //   locationName: file.location_name,
        //   locationId: file.location_id,
        //   isDir: file.type === 'folder' ? true : false,
        // }));
        const formattedFiles = res
          .filter(file => !(file.golden_config === null && file.type === 'file'))
          .map((file) => {
            return {
              id: file.file_name,
              name: file.file_name,
              modDate: file.updated_at,
              size: file.golden_config_file_size ? parseSizeToBytes(file.golden_config_file_size) : 'Unknown size',
              content: file.golden_config || 'No config available',
              deviceName: file.device_name,
              locationName: file.location_name,
              locationId: file.location_id,
              isDir: file.type === 'folder',
            };
          });

        setFiles(formattedFiles);
        setDetails(res);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });

  }, [data?.r_dvc_unique_identifier, customerId]);


  const folderChain = React.useMemo(() => {
    const customerName = localStorage.getItem('customerName') || 'Unknown Customer';
    const locationName = details[0]?.location_name || 'Unknown Location';
    const uniqueIdentifier = details[0]?.unique_identifier || 'Unknown Identifier';

    const customNameParts = [
      {
        id: 'customer',
        name: `${customerName} - cmdb`,
      },
      {
        id: 'location',
        name: locationName,
      },
      {
        id: 'identifier',
        name: uniqueIdentifier,
      },
    ];


    const folderChain = customNameParts.map((part) => ({
      id: part.id,
      name: part.name,
      isDir: true,
      onClick: () => {
        if (part.id === 'customer') {
          setKeyPrefix(`${customerName} - cmdb`);
        } else if (part.id === 'location') {
          setKeyPrefix(`${customerName} - cmdb / ${locationName}`);
        } else if (part.id === 'identifier') {
          setKeyPrefix(`${customerName} - cmdb / ${locationName} / ${uniqueIdentifier}`);
        }
      },
    }));

    if (folderPrefix !== '/') {
      let currentPrefix = '';
      const additionalFolders = folderPrefix
        .replace(/\/*$/, '')
        .split('/')
        .map((prefixPart) => {
          currentPrefix = currentPrefix ? path.join(currentPrefix, prefixPart) : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
            onClick: () => {
              setKeyPrefix(currentPrefix);
            },
          };
        });

      // Remove duplicates before adding additional folders
      additionalFolders.forEach((folder) => {
        const exists = folderChain.some((f) => f.id === folder.id && f.name === folder.name);
        if (!exists) {
          folderChain.push(folder);
        }
      });
    }

    return folderChain;
  }, [folderPrefix, details]);

  // const folderChain = React.useMemo(() => {
  //   const customerName = localStorage.getItem('customerName') || 'Unknown Customer';
  //   const locationName = details[0]?.location_name || 'Unknown Location';
  //   const uniqueIdentifier = details[0]?.unique_identifier || 'Unknown Identifier';

  //   const customNameParts = [
  //     {
  //       id: 'customer',
  //       name: `${customerName} - cmdb`,
  //     },
  //     {
  //       id: 'location',
  //       name: locationName,
  //     },
  //     {
  //       id: 'identifier',
  //       name: uniqueIdentifier,
  //     },
  //   ];

  //   // If there's an active folder, only include up to that folder in the chain
  //   const slicedParts = activeFolder
  //     ? customNameParts.slice(0, customNameParts.findIndex((part) => part.id === activeFolder) + 1)
  //     : customNameParts;

  //   const folderChain = slicedParts.map((part) => ({
  //     id: part.id,
  //     name: part.name,
  //     isDir: true,
  //     onClick: () => {
  //       // When a folder is clicked, set it as the active folder and update the key prefix
  //       setActiveFolder(part.id);
  //       if (part.id === 'customer') {
  //         setKeyPrefix(`${customerName} - cmdb`);
  //       } else if (part.id === 'location') {
  //         setKeyPrefix(`${customerName} - cmdb / ${locationName}`);
  //       } else if (part.id === 'identifier') {
  //         setKeyPrefix(`${customerName} - cmdb / ${locationName} / ${uniqueIdentifier}`);
  //       }
  //     },
  //   }));

  //   if (folderPrefix !== '/') {
  //     let currentPrefix = '';
  //     const additionalFolders = folderPrefix
  //       .replace(/\/*$/, '')
  //       .split('/')
  //       .map((prefixPart) => {
  //         currentPrefix = currentPrefix ? path.join(currentPrefix, prefixPart) : prefixPart;
  //         return {
  //           id: currentPrefix,
  //           name: prefixPart,
  //           isDir: true,
  //           onClick: () => {
  //             // When additional folder is clicked, update the active folder and prefix
  //             setActiveFolder(currentPrefix);
  //             setKeyPrefix(currentPrefix);
  //           },
  //         };
  //       });

  //     additionalFolders.forEach((folder) => {
  //       const exists = folderChain.some((f) => f.id === folder.id && f.name === folder.name);
  //       if (!exists) {
  //         folderChain.push(folder);
  //       }
  //     });
  //   }

  //   return folderChain;
  // }, [folderPrefix, details, activeFolder]);

  const handleFileAction = useCallback(
    (data) => {
      if (!locationBackup && data.id === 'mouse_click_file' && data.payload.clickType === 'double' && !data.payload.file.isDir) {
        setSelectedFileDetails(data.payload.file)
        setSelectedFile(data.payload.file.content);
        setReadFileDialog(true);

      }
      if (!backupTrue && locationBackup && data.id === 'mouse_click_file' && data.payload.clickType === 'double' && !data.payload.file.isDir) {
        setSelectedFileDetails(data.payload.file)
        setSelectedFile(data.payload.file.content);
        setReadFileDialog(true);
      }
      if (backupTrue && data.id === 'mouse_click_file' && data.payload.clickType === 'double' && !data.payload.file.isDir) {
        const eventId = data.payload.file.eventId;
        getdiffCurrPrev(customerId, eventId)
          .then((res) => {
            setSelectedFileDetails(data.payload.file)
            setLoading(false);
            if (res?.r_grp_smry_diff_html === "") {
              setSelectedFile("Data not available");
            } else {
              setSelectedFile(res?.r_grp_smry_diff_html);
            }
            setReadFileDialog(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(error.message);
          });

        setSelectedFile(data.payload.file.content);
        setReadFileDialog(true);
      }
      if (locationBackup && data.id === 'mouse_click_file' && data.payload.clickType === 'double' && data.payload.file.isDir && data.payload.file.id !== 'Backups' && data.payload.file.id !== 'Golden_Backups') {
        const uniqueIdentifier = data.payload.file.id
        viewBackUpDetails(customerId, uniqueIdentifier)
          .then((res) => {
            setLoading(false);
            const parseSizeToBytes = (sizeStr) => {
              if (!sizeStr) return 0;
              const [value, unit] = sizeStr.split(' ');
              const numValue = parseFloat(value);

              switch (unit) {
                case 'KB':
                  return numValue * 1024;
                case 'MB':
                  return numValue * 1024 * 1024;
                case 'GB':
                  return numValue * 1024 * 1024 * 1024;
                default:
                  return numValue;
              }
            };

            const formattedFiles = res.map(file => ({
              id: file.file_name,
              name: file.file_name,
              modDate: file.updated_at,
              size: file.golden_config_file_size ? parseSizeToBytes(file.golden_config_file_size) : 'Unknown size',
              content: file.golden_config || 'No config available',
              deviceName: file.device_name,
              locationName: file.location_name,
              locationId: file.location_id,
              uniqueIdentifier: file.unique_identifier,
              isDir: file.type === 'folder' ? true : false,
            }));

            setFiles(formattedFiles);
            setDetails(res);
          })
          .catch((error) => {
            setLoading(false);
            setError(error.message);
          });
      }
      if (customerBackup && data.id === 'mouse_click_file' && data.payload.clickType === 'double' && data.payload.file.isDir && data.payload.file.id !== 'Backups' && data.payload.file.id !== 'Golden_Backups') {
        const uniqueIdentifier = data.payload.file.eventId
        let payload = {
          endRow: 200,
          filterModel: {},
          global_keyword: "",
          location_uuids: [uniqueIdentifier],
          sortModel: [],
          startRow: 0
        }
        getconfigBackupEventsviaSidebar(customerId, payload)
          .then((res) => {
            setLoading(false);
            const response = res.data
            const formattedFiles = response.map((file) => {
              return {
                id: file.r_dvc_unique_identifier,
                name: file.r_dvc_unique_identifier,
                eventId: file.cmdbnd_id,
                nameId: "location_name",
                isDir: true,
              };
            });
            setFiles(formattedFiles);
            setBackupTrue(false);
            setLocationBackup(true)
          })
          .catch((error) => {
            setLoading(false);
            setError(error.message);
          });
      }

      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.files && data.payload.files.length === 1) {
          const targetFile = data.payload.files[0];
          if ((targetFile.id === 'Backups' || targetFile.id === 'Golden_Backups') && !locationBackup && !backupTrue) {
            setLoading(true);
            viewBackUpFolderDetails(customerId, uniqueIdentifier, targetFile.id)
              .then((res) => {
                setLoading(false);
                const parseSizeToBytes = (sizeStr) => {
                  if (!sizeStr) return 0;
                  const [value, unit] = sizeStr.split(' ');
                  const numValue = parseFloat(value);

                  switch (unit) {
                    case 'KB':
                      return numValue * 1024;
                    case 'MB':
                      return numValue * 1024 * 1024;
                    case 'GB':
                      return numValue * 1024 * 1024 * 1024;
                    default:
                      return numValue;
                  }
                };
                const formattedFiles = res.map((file) => {
                  return {
                    id: file.file_name,
                    name: file.file_name,
                    modDate: file.created_at,
                    size: parseSizeToBytes(file.device_file_size),
                    eventId: file.cmdb_backup_id,
                    isDir: false,
                    deviceName: file.device_name,
                    locationName: file.location_name,
                    locationId: file.location_id,

                  };
                });
                setFiles(formattedFiles);
                setBackupTrue(true);
              })
              .catch((error) => {
                setLoading(false);
                setError(error.message);
              });
          }
          if ((targetFile.id === 'Backups' || targetFile.id === 'Golden_Backups') && locationBackup && !backupTrue) {
            setLoading(true);
            const uniqueIdentifier = data.payload.targetFile.uniqueIdentifier
            viewBackUpFolderDetails(customerId, uniqueIdentifier, targetFile.id)
              .then((res) => {
                setLoading(false);
                const parseSizeToBytes = (sizeStr) => {
                  if (!sizeStr) return 0;
                  const [value, unit] = sizeStr.split(' ');
                  const numValue = parseFloat(value);

                  switch (unit) {
                    case 'KB':
                      return numValue * 1024;
                    case 'MB':
                      return numValue * 1024 * 1024;
                    case 'GB':
                      return numValue * 1024 * 1024 * 1024;
                    default:
                      return numValue;
                  }
                };
                const formattedFiles = res.map((file) => {
                  return {
                    id: file.file_name,
                    name: file.file_name,
                    modDate: file.created_at,
                    size: parseSizeToBytes(file.device_file_size),
                    eventId: file.cmdb_backup_id,
                    isDir: false,
                    deviceName: file.device_name,
                    locationName: file.location_name,
                    locationId: file.location_id,
                    uniqueIdentifier: file.unique_identifier
                  };
                });
                setFiles(formattedFiles);
                setBackupTrue(true);
              })
              .catch((error) => {
                setLoading(false);
                setError(error.message);
              });
          }
        }

        if (data.payload.targetFile && data.payload.targetFile.isDir) {
          if (data.payload.targetFile.id === "location") {
            let payload = {
              endRow: 200,
              filterModel: {},
              global_keyword: "",
              location_uuids: [details[0].location_id],
              sortModel: [],
              startRow: 0
            }
            getconfigBackupEventsviaSidebar(customerId, payload)
              .then((res) => {
                setLoading(false);
                const response = res.data
                const formattedFiles = response.map((file) => {
                  return {
                    id: file.r_dvc_unique_identifier,
                    name: file.r_dvc_unique_identifier,
                    eventId: file.cmdbnd_id,
                    nameId: "location_name",
                    isDir: true,
                  };
                });
                setFiles(formattedFiles);
                setBackupTrue(false);
                setLocationBackup(true)
              })
              .catch((error) => {
                setLoading(false);
                setError(error.message);
              });
          }
          if (data.payload.targetFile.id === "customer") {
            let payload =
              { "startRow": 0, "endRow": 200, "sortModel": { "g_created_at": { "colId": "g_created_at", "sort": "asce" } }, "filterModel": {}, "global_keyword": "" }
            getLocations(customerId, payload)
              .then((res) => {
                setLoading(false);
                const response = res.data
                const formattedFiles = response.map((file) => {
                  return {
                    id: file.r_location_name,
                    name: file.r_location_name,
                    eventId: file.location_id,
                    isDir: true,
                  };
                });
                setFiles(formattedFiles);
                setBackupTrue(false);
                setLocationBackup(false)
                setCustomerBackup(true)
              })
              .catch((error) => {
                setLoading(false);
                setError(error.message);
              });
          }
          if (data.payload.targetFile.id === "identifier") {
            viewBackUpDetails(customerId, data?.r_dvc_unique_identifier)
              .then((res) => {
                setLoading(false);

                const parseSizeToBytes = (sizeStr) => {
                  if (!sizeStr) return 0;
                  const [value, unit] = sizeStr.split(' ');
                  const numValue = parseFloat(value);

                  switch (unit) {
                    case 'KB':
                      return numValue * 1024;
                    case 'MB':
                      return numValue * 1024 * 1024;
                    case 'GB':
                      return numValue * 1024 * 1024 * 1024;
                    default:
                      return numValue;
                  }
                };

                const formattedFiles = res.map(file => ({
                  id: file.file_name,
                  name: file.file_name,
                  modDate: file.updated_at,
                  size: file.golden_config_file_size ? parseSizeToBytes(file.golden_config_file_size) : 'Unknown size',
                  content: file.golden_config || 'No config available',
                  deviceName: file.device_name,
                  locationName: file.location_name,
                  locationId: file.location_id,
                  isDir: file.type === 'folder' ? true : false,
                }));

                setFiles(formattedFiles);

                setDetails(res);
              })
              .catch((error) => {
                setLoading(false);
                setError(error.message);
              });
          }
          const newPrefix = `${data.payload.targetFile.id}`;
          setKeyPrefix(newPrefix);
        }
      }
    },
    [customerId, uniqueIdentifier, navigate]
  );

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ width: '100%', padding: '18px' }}>
        <div style={{ height: '600px' }}>
          <FileBrowser
            files={files}
            instanceId={'cmdb-explorer'}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
          >
            <FileNavbar />
            <FileToolbar />
            <FileList />
          </FileBrowser>
          {readFileDialog && (
            <CMDBDialog
              selectedFileDetails={selectedFileDetails}
              openDialog={readFileDialog}
              selectedFile={selectedFile}
              details={details}
              handleClose={() => setReadFileDialog(false)}
            />
          )}
        </div>
      </Box>
    </>
  );
};
