
import { TestbedInstance, instance as modem } from '../modem';

export const getConfigChangeCards = async (id, data, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/cmdb-statistics`, payload).then((response) => {
    return response?.data;
  });
  return newPromise;
};
// const newPromise = modem.get(`${id}/cmdb/get-backup-file/get_curr_golden_backup_files/${eventId}`).then((response) => {


export const getEventsDifferenceData = async (id, uniqueIdentifier, data) => {
  const newPromise = modem.post(`${id}/cmdb/get-device-changes/${uniqueIdentifier}`, data).then((response) => {
    console.log("res",response?.data);
    return response?.data;
  });
  return newPromise;
};

export const approveEventChange = async (id, eventId) => {
  const newPromise = modem.post(`${id}/cmdb/${eventId}/approve-device-changes`).then((response) => {
    console.log("res11",response?.data);
    return response?.data;
  });
  return newPromise;
};

export const unApproveEventChange = async (id, eventId) => {
  const newPromise = modem.post(`${id}/cmdb/${eventId}/un-approve-device-changes`).then((response) => {
    console.log("res",response?.data);
    return response?.data;
  });
  return newPromise;
};

export const getConfigChangeEventsTable = async (id, data) => {
  const newPromise = modem.post(`${id}/network-devices/config-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getConfigChangeEventsTableLogs = async (id, data) => {

  const newPromise = modem.post(`${id}/network-devices/config-change-events/logs`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getconfigBackupEventsviaSidebar = async (id, data) => {

  const newPromise = modem.post(`${id}/inventory/cmdb`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};


export const getBackupEventsTable = async (id, data) => {
  const testbedModem = TestbedInstance();

  const newPromise = modem.post(`${id}/events/cmdb`, data).then((response) => {
      console.log("res",response.data.data);
      
    return response.data.data;
  });
  return newPromise;
};

export const getdiff = async (id, eventId) => {
  const newPromise = modem.get(`${id}/cmdb/get-backup-file/get_curr_golden_backup_files/${eventId}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

// get_curr_prev_backup_files
export const getdiffCurrPrev = async (id, eventId) => {
  const newPromise = modem.get(`${id}/cmdb/get-backup-file/get_curr_prev_backup_files/${eventId}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const viewDetails = async (id, backupId) => {
  const newPromise = modem.get(`${id}/cmdb/get-backup-file/${backupId}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
}

export const viewBackUpDetails = async (id, deviceId) => {
  const newPromise = modem.get(`${id}/cmdb/get_golden_config_file/${deviceId}`).then((response) => {        
    return response.data.data;
  });
  return newPromise;
 
}

export const viewBackUpFolderDetails = async (id, deviceId,folderName) => {
  const newPromise = modem.get(`${id}/cmdb/get_cmdb_config_backup_files/${deviceId}?folderName=${folderName}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
}