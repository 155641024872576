/* eslint-disable */
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu'; // Import MenuIcon
import { Box, Drawer, IconButton } from '@mui/material'; // Import Drawer and IconButton
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import navConfig from '../../Assets/NavConfigPro';
import '../../Assets/Prosidebar.css';
import Logo from '../../components/Logo';
import LogoWithoutText from '../../components/LogoWithoutText';
import navConfigCollapsed from './navConfigCollapsed';
import './sidebarprocopy.css';
import useMediaQuery from '@mui/material/useMediaQuery'; // Import useMediaQuery

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    borderRightStyle: 'solid',
    borderRightColor: '#cdcccc54',
  },
}));

const useStyles = makeStyles({
  closeMenu: {
    position: 'absolute',
    color: '#ffffff',
    right: 0,
    zIndex: '9999',
    borderRadius: '4px 0 0 4px',
    background: '#9e9e9e',
    height: '24px',
    top: '58px',
    cursor: 'pointer',
    marginTop: '-15px'
  },
  SidebarContent: {
    overflow: 'scroll',
    height: 'calc(100vh - 50px)',
    marginRight: '-10px',
    paddingBottom: '20px',
    backgroundColor: 'rgb(250, 251, 252)',
    width: '275px',

  },
});

export default function DashboardSidebar(props) {
  const { menu, menuHandler } = props;
  const classes = useStyles();
  const isTablet = useMediaQuery('(max-width: 992px)');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState({
    date: new Date().toLocaleDateString("en-CA"),
    time: new Date().toLocaleTimeString("en-GB"),
  });
  const [nav, setNavigation] = useState(
    navConfig.map((i) => {
      i.open = i.children.findIndex((j) => j.to === window.location.pathname) !== -1 ? true : undefined;
      return i;
    })
  );
  useEffect(() => {
    // const timer = setInterval(() => {
    //   setCurrentDateTime({
    //     date: new Date().toLocaleDateString("en-CA"),
    //     time: new Date().toLocaleTimeString("en-GB"),
    //   });
    // }, 1000);

    // return () => clearInterval(timer);
  }, []);

  const onOpenChange = (open, item) => {
    setNavigation((_nav) =>
      _nav.map((i) => {
        if (i.title === item.title) {
          i.open = !open;
        }
        return i;
      })
    );
  };

  const renderMenuItem = (children) =>
    children.map((item, index) => (
      <MenuItem key={item.to + index} icon={item.icon} active={window.location.pathname === item.to}>
        {item.title}
        <NavLink to={item.to} />
      </MenuItem>
    ));

  const renderContent = (
    <ProSidebar id="header" collapsed={menu}>
      <SidebarHeader>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', maxHeight: '70px' }}>
          {/* <div className={classes.closeMenu} onClick={menuHandler}>
            {menu ? (
              <KeyboardDoubleArrowRightIcon />
            ) : (
              <KeyboardDoubleArrowLeftIcon />
            )}
          </div> */}
          {menu ? <LogoWithoutText sx={{ ml: 13 }} /> : <Logo sx={{ ml: -10 }} />}
        </Box>
        <div
          style={{
            color: 'black',
            marginLeft: '8px',
            padding: '4px 8px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            fontSize: '0.9rem',
            fontWeight: '500',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          Last updated: {currentDateTime.date} {currentDateTime.time}
        </div>

      </SidebarHeader>
      <SidebarContent className={classes.SidebarContent}>
        {menu ? (
          <Menu>
            {navConfigCollapsed.map((item, index) => (
              <MenuItem key={item.title + index} icon={item.icon} title={item.title}>
                {item.title}
                <NavLink to={item.to} />
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu>
            {navConfig.map((item, index) => (
              <SubMenu
                key={item.title + index}
                open={item.open}
                onOpenChange={(e) => onOpenChange(e, item)}
                icon={item.icon}
                title={item.title}
              >
                {renderMenuItem(item.children)}
              </SubMenu>
            ))}
          </Menu>
        )}
      </SidebarContent>
    </ProSidebar>
  );

  return (
    <RootStyle>
      {isTablet ? (
        <>
          <IconButton onClick={() => setOpenDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            {renderContent}
          </Drawer>
        </>
      ) : (
        renderContent
      )}
    </RootStyle>
  );
}
