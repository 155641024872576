

// import { Grid, LinearProgress, Paper, Typography, CircularProgress } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { useHealthContext } from '../../../userContextHealth';
// import MainCpuChart from '../../../MainCpuChart/MainCpuChart';

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     height: 260,
//     textAlign: 'center', // Center align the message
// }));

// const CPUChart = ({ utilizationExpanded, allData }) => {
//     const { healthDashState, getStartEnd } = useHealthContext();
//     const { customerId } = useContext(UserContext);
//     const [seriesData, setSeriesData] = useState([]);
//     const [xAxisData, setXAxisData] = useState([]);
//     const [chartLoading, setChartLoading] = useState(true);

//     const solveCondition = async () => {
//         await fetchCpuUsage();
//     };

//     const fetchCpuUsage = async () => {
//         const data = getStartEnd();

//         // Start the loading indicator
//         setChartLoading(true);

//         // Simulate API call delay
//         setTimeout(() => {
//             const convertedArray = allData?.cpu_utilizations?.map(Number); // Simulating CPU utilization data
//             const formattedXAxisData = allData?.timestamps?.map(timestamp => {
//                 const date = new Date(timestamp * 1000);
//                 const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//                 const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//                 return `${timeString}\n${dateString}`;
//             });

//             // Update state with fetched data
//             setSeriesData([{ name: 'CPU Utilization', data: convertedArray || [] }]);
//             setXAxisData(formattedXAxisData || []);
            
//             // Stop loading once data is ready
//             setChartLoading(false);
//         }, 1000); // Simulating a delay of 1 second
//     };

//     useEffect(() => {
//         solveCondition();
//     }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, utilizationExpanded, allData]);

//     return (
//         <>
//             {chartLoading && <LinearProgress />} {/* Top-level loading bar */}
//             <Paper sx={{ padding: '10px' }} variant="outlined">
//                 <Grid container spacing={1}>
//                     <Grid item lg={12} md={12} sm={12}>
//                         <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                             CPU Utilization
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item lg={12} md={12} sm={12}>
//                     <ChartWrapperStyle dir="ltr">
//                         {chartLoading ? ( // Show circular loader while data is loading
//                             <CircularProgress />
//                         ) : (
//                             seriesData?.length > 0 && xAxisData?.length > 0 ? ( // Render chart once data is available
//                                 <MainCpuChart seriesData={seriesData} xAxisData={xAxisData} />
//                             ) : ( // Show message if no data is available
//                                 <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
//                                     No data at the moment
//                                 </Typography>
//                             )
//                         )}
//                     </ChartWrapperStyle>
//                 </Grid>
//             </Paper>
//         </>
//     );
// };

// export default CPUChart;


import { Grid, LinearProgress, Paper, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';
import MainCpuChart from '../../../MainCpuChart/MainCpuChart';

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 260,
    textAlign: 'center', // Center align the message
}));

const CPUChart = ({ utilizationExpanded, allData }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [chartLoading, setChartLoading] = useState(true);

    const solveCondition = async () => {
        await fetchCpuUsage();
    };

    const fetchCpuUsage = async () => {
        const data = getStartEnd();

        // Start the loading indicator
        setChartLoading(true);

        // Simulate API call delay
        setTimeout(() => {
            const cpuUtilizations = allData?.cpu_utilizations?.map(Number) || [];
            const timestamps = allData?.timestamps?.map(ts => ts * 1000) || []; // Convert to milliseconds

            // Ensure both arrays are of the same length
            const minLength = Math.min(cpuUtilizations.length, timestamps.length);
            const formattedSeriesData = [
                {
                    name: 'CPU Utilization',
                    data: cpuUtilizations.slice(0, minLength).map((util, index) => ({
                        x: timestamps[index],
                        y: util,
                    })),
                },
            ];

            // Update state with fetched data
            setSeriesData(formattedSeriesData);
            
            // Stop loading once data is ready
            setChartLoading(false);
        }, 1000); // Simulating a delay of 1 second
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, allData]);

    return (
        <>
            {chartLoading && <LinearProgress />} {/* Top-level loading bar */}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            CPU Utilization
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        {chartLoading ? ( // Show circular loader while data is loading
                            <CircularProgress />
                        ) : (
                            seriesData?.length > 0 && seriesData[0].data.length > 0 ? ( // Render chart once data is available
                                <MainCpuChart seriesData={seriesData} />
                            ) : ( // Show message if no data is available
                                <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                                    No data at the moment
                                </Typography>
                            )
                        )}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default CPUChart;
