import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState, useRef  } from 'react';

import { useLocation , useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useHealthContext } from '../userContextHealth';
import CPUChart from '../ArubaOs/components/Overview/CPUChart';
import MemoryFreeChart from '../ArubaOs/components/Overview/MemoryFreeChart';
import NetworkClients from '../ArubaOs/components/Overview/NetworkClients';
import OverviewDetails from '../ArubaOs/components/Overview/OverviewDetails';
import UtilizationChart from '../ArubaOs/components/Radio/UtilizationChart';
import Radio0FramesChart from '../ArubaOs/components/Radio/Radio0FramesChart';
import NoiseFloorChart from '../ArubaOs/components/Radio/NoiseFloorChart';
import RadioMgtFrames from '../ArubaOs/components/Radio/RadioMgtFrames';
import ClientsTable from '../ArubaOs/components/Client/ClientsTable';
import NetworksTable from '../ArubaOs/components/Networks/NetworksTable';
import MainInterfaceTable from '../MainInterfaceTable/MainInterfaceTable';
import MainThroughputBandwidth from '../MainThroughputBandwidth/MainThroughputBandwidth';

import InterfaceSelector from '../InterfaceSelector';
// import { useHealthContext } from '../userContextHealth';
// import InterfaceSelector from '../InterfaceSelector';
// import OverviewDetails from './components/System/OverviewDetails';
import CPUUsageRatioChart from '../Fortinet/components/HACluster/CPUUsageRatioChart';
import IPSEventsTotal from '../Fortinet/components/HACluster/IPSEventsTotal';
import MEMUsageRatio from '../Fortinet/components/HACluster/MEMUsageRatio';
import MemberBytesTotal from '../Fortinet/components/HACluster/MemberBytesTotal';
import MemberPacketChart from '../Fortinet/components/HACluster/MemberPacketChart';
import MemberStatus from '../Fortinet/components/HACluster/MemberStatus';
import NetworkUsageRatioChart from '../Fortinet/components/HACluster/NetworkUsageRatioChart';
import HaSessionsChart from '../Fortinet/components/HACluster/SessionsChart';
import VirusEventsTotal from '../Fortinet/components/HACluster/VirusEventsTotal';
// import CPUChart from './components/System/CPUChart';
import MemoryChart from '../Fortinet/components/System/MemoryChart';
import SessionsChart from '../Fortinet/components/System/SessionsChart';
import VDOMUsageChart from '../Fortinet/components/System/VDOMUsageChart';
import VPNClients from '../Fortinet/components/System/VPNClients';
import VersionBox from '../Fortinet/components/System/VersionBox';
import MemoryChartDetailed from '../Fortinet/components/System/MemoryChartDetailed';
// import ThroughputBandwidth from './components/Interface/ThroughputBandwidth';
import ClientVpnConnections from '../Fortinet/components/VPN/ClientVpnConnections';
import BandwidthReceive from '../Fortinet/components/VPN/BandwidthReceive';
import BandwidthTransmit from '../Fortinet/components/VPN/BandwidthTransmit';
import VPNstatusTable from '../Fortinet/components/VPN/VPNstatusTable';
// import InterfaceStatusTable from './components/Interface/InterfaceStatusTable';
import CerificateValidityTable from '../Fortinet/components/Certificates/CerificateValidityTable';
import AllBandwidths from '../Fortinet/components/SD-WAN/AllBandwidths';
import VirtualWanActiveSessions from '../Fortinet/components/SD-WAN/VirtualWanActiveSessions';
import VirtualWanJitter from '../Fortinet/components/SD-WAN/VirtualWanJitter';
import VirtualWanLatency from '../Fortinet/components/SD-WAN/VirtualWanLatency';
import VirtualWanPacketLoss from '../Fortinet/components/SD-WAN/VirtualWanPacketLoss';
import VirtualWanSentReceivedTotal from '../Fortinet/components/SD-WAN/VirtualWanSentReceivedTotal';
import VirtualWanStatus from '../Fortinet/components/SD-WAN/VirtualWanStatus';
import SdwanStatusTable from '../Fortinet/components/SD-WAN/SdwanStatusTable';
import AvailabilityPage from '../Fortinet/components/Availability/AvailabilityPage';
import { networkHealthCpuUsage } from '../../../../API/NetworkDevices/health';
import RadioTable from '../ArubaOs/components/Radio/RadioTable';
import MainChart from '../MainThroughputBandwidth/MainChart';
import MainOverviewDetails from '../MainOverviewDetails/MainOverviewDetails';
import MacTable from '../ArubaOs/components/MacTable/MacTable';
import RoutingTable from '../ArubaOs/components/Router/RoutingTable';
import SdWanTable from '../ArubaOs/components/SdWan/SdWanTable';
import Vdom from '../ArubaOs/components/Vdom/VdomTab';
import VdomTab from '../ArubaOs/components/Vdom/VdomTab';
import HaCluster from '../ArubaOs/components/HaTable/HaCluster';






const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});




const MainHealthDashboard = ({ expandedState }) => {
    const classess = useStyles();
    const { healthDashState, getStartEnd } = useHealthContext();
    const [searchParams] = useSearchParams();
   
    const locationUuid = searchParams.get('locationUuid');
    const identifier = searchParams.get('identifier');
    const [overviewExpanded, setOverviewExpanded] = useState(true);
    const [utilizationExpanded, setUtilizationExpanded] = useState(true);
    const [radioExpanded, setRadioExpanded] = useState(false);
    const [clientsExpanded, setClientsExpanded] = useState(false);
    const [macExpanded, setMacExpanded] = useState(false);
    const [networksExpanded, setNetworksExpanded] = useState(false);
    const [routerExpanded, setRouterExpanded] = useState(false);
    const [systemExpanded, setSystemExpanded] = useState(true);
    const [haClusterExpanded, setHaClusterExpanded] = useState(false);
    const [interfaceExpanded, setInterfanceExpanded] = useState(false)
    const [vpnExpanded, setVpnExpanded] = useState(false);
    const [sdwanExpanded, setSdwanExpanded] = useState(false);
    const [vdomExpanded, setVdomExpanded] = useState(false);
    const [certificateExpanded, setCertificateExpanded] = useState(false);
    const [availabilityExpanded, setAvailabilityExpanded] = useState(false);
    const [allData, setAllData] = useState();
    const [all1Data, setAll1Data] = useState();
    const { customerId } = useContext(UserContext);
    const [selectedInterface, setSelectedInterface] = useState(null);
    const [showAccordion, setShowAccordion] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const prevCustomerId = useRef();
    const { cpuUsageData, deviceTypeName, rDvcController,availabilityData } = location.state || {};

    useEffect(() => {
        if (cpuUsageData) {
            setAllData(cpuUsageData);
        }
    }, [cpuUsageData]);

    
      
   
    const handleOverviewChange = () => {
        setOverviewExpanded(!overviewExpanded);
    };

    const handleUtilizationChange = () => {
        setUtilizationExpanded(!utilizationExpanded);
    };

    const handleRadioChange = () => {
        setRadioExpanded(!radioExpanded);
    };

    const handleClientsChange = () => {
        setClientsExpanded(!clientsExpanded);
    };

    const handleInterfaceChange = () => {
        setInterfanceExpanded(!interfaceExpanded);
    };

    const handleNetworksChange = () => {
        setNetworksExpanded(!networksExpanded);
    };

    const handleRouterChange = () => {
        setRouterExpanded(!routerExpanded);
    }

    const handleMacChange = () => {
        setMacExpanded(!macExpanded);
    }

    const handleAvailabilityChange = () => {
        setAvailabilityExpanded(!availabilityExpanded);
    };

    if (!identifier) {
        return <Stack direction="column" className={classess.noDataSelectedContainer}>
            <Typography variant="h3" display="block" gutterBottom>
                Please select device
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
                to view dashboard
            </Typography>
        </Stack>
    }

    const handleSystemChange = () => {
        setSystemExpanded(!systemExpanded);
    };

    const handleHaClusterChange = () => {
        setHaClusterExpanded(!haClusterExpanded);
    };

    // const handleInterfaceChange = () => {
    //     setInterfanceExpanded(!interfaceExpanded);
    // };

    const handleVpnChange = () => {
        setVpnExpanded(!vpnExpanded);
    };

    const handleCertificateChange = () => {
        setCertificateExpanded(!certificateExpanded);
    };

    const handleSdwanChange = () => {
        setSdwanExpanded(!sdwanExpanded);
    };

    const handleVdomChange = () => {
        setVdomExpanded(!vdomExpanded);
    }
console.log("allData from MHD",allData);
    return (
<>
{rDvcController === "WLC MEMBER" ? (
        <Box>
        <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Overview</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <MainOverviewDetails overviewExpanded={overviewExpanded} allData={allData} />
                                </Grid>
                                {/* <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} allData={allData} />
                                </Grid> */}
                            </Grid>
                            
                        </Grid>
                    </AccordionDetails>
                </Accordion> 
                <Accordion expanded={availabilityExpanded} onChange={handleAvailabilityChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Availability</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <AvailabilityPage availabilityExpanded={availabilityExpanded} all1Data={all1Data}  />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                
        </Box>
):(
    <Box>
                     
            <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Overview</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <MainOverviewDetails overviewExpanded={overviewExpanded} allData={allData} />
                                </Grid>
                                {/* <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} allData={allData} />
                                </Grid> */}
                            </Grid>
                            
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={utilizationExpanded} onChange={handleUtilizationChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}> CPU Utilization & Memory Usage</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                {/* <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} allData={allData} />
                                </Grid> */}
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={6} md={6} lg={6}>
                                    {allData && <CPUChart utilizationExpanded={utilizationExpanded} allData={allData} />}
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    {allData && <MemoryFreeChart utilizationExpanded={utilizationExpanded} allData={allData} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={availabilityExpanded} onChange={handleAvailabilityChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Availability</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <AvailabilityPage availabilityExpanded={availabilityExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>


                {(deviceTypeName === "ACCESS POINT" || deviceTypeName === "WLC" ) &&
                    <Accordion expanded={radioExpanded} onChange={handleRadioChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>Radio</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        < RadioTable radioExpanded={radioExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

                <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ fontWeight: 700 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{ pb: 2 }}>
                                {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded} />
                                </Box> */}
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                {/* <Grid item sm={8} md={8} lg={8}>
                                    <MainThroughputBandwidth expandedState={interfaceExpanded} />
                                </Grid> */}
                                {/* <Grid item sm={8} md={8} lg={8}>
                                    <MainChart expandedState={interfaceExpanded} />
                                </Grid> */}
                                <Grid item sm={12} md={12} lg={12}>
                                    <MainInterfaceTable interfaceExpanded={interfaceExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {(deviceTypeName === "ACCESS POINT" || deviceTypeName === "WLC" )  &&
                <Accordion expanded={clientsExpanded} onChange={handleClientsChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Clients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <ClientsTable clientsExpanded={clientsExpanded} allData={allData} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>}

                {deviceTypeName === "SWITCH" &&
                    <Accordion expanded={macExpanded} onChange={handleMacChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>MAC Table</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <MacTable macExpanded={macExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

                {(deviceTypeName === "ACCESS POINT" || deviceTypeName === "WLC") &&
                    <Accordion expanded={networksExpanded} onChange={handleNetworksChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>Networks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <NetworksTable networksExpanded={networksExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

                {(deviceTypeName === "Router" ) &&
                    <Accordion expanded={routerExpanded} onChange={handleRouterChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>RoutingTable</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <RoutingTable routerExpanded={routerExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

                    {(deviceTypeName === "FIREWALL") &&
                    <Accordion expanded={haClusterExpanded} onChange={handleHaClusterChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>HA Cluster</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <HaCluster haClusterExpanded={haClusterExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

                {/* <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Availibity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <OverviewDetails overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={6} md={6} lg={6}>
                                    <CPUChart overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <MemoryFreeChart overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> */}

                {/* <Accordion expanded={systemExpanded} onChange={handleSystemChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 600 }}>System</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <OverviewDetails systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={7} md={7} lg={7}>
                                    <CPUChart systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid container item direction="row" spacing={1} sm={2} md={2} lg={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <VersionBox systemExpanded={systemExpanded} />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <VPNClients systemExpanded={systemExpanded} />
                                    </Grid>
                                </Grid>
                                <Grid item sm={3} md={3} lg={3}>
                                    <MemoryChart systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <MemoryChartDetailed systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <SessionsChart systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VDOMUsageChart systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> */}

                {/* {healthDashState.selectedDevice?.r_dvc_ha_type === "1" ? "" : (
                    <Accordion expanded={haClusterExpanded} onChange={handleHaClusterChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>HA Cluster</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                     {allData && <CPUUsageRatioChart expandedState={haClusterExpanded} allData={allData} />}   
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                       {allData && <HaSessionsChart expandedState={haClusterExpanded} allData={allData} /> } 
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <MemberStatus expandedState={haClusterExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        {allData && <NetworkUsageRatioChart expandedState={haClusterExpanded} allData={allData} /> }
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        {allData && <MemberPacketChart expandedState={haClusterExpanded} allData={allData} /> }
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <VirusEventsTotal expandedState={haClusterExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        {allData && <MEMUsageRatio expandedState={haClusterExpanded} allData={allData} />}
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        {allData && <MemberBytesTotal expandedState={haClusterExpanded} allData={allData} />}
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                     {allData && <IPSEventsTotal expandedState={haClusterExpanded} allData={allData} /> }   
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )} */}

                {/* <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ fontWeight: 600 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{ pb: 2 }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded} />
                                </Box>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={8} md={8} lg={8}>
                                    <MainThroughputBandwidth expandedState={interfaceExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <MainInterfaceTable expandedState={interfaceExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> */}

                {/* <Accordion expanded={vpnExpanded} onChange={handleVpnChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>VPN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                   {allData && <ClientVpnConnections expandedState={vpnExpanded} allData={allData}/> } 
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                     {allData && <BandwidthReceive expandedState={vpnExpanded} allData={allData} />}   
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      {allData && <BandwidthTransmit expandedState={vpnExpanded} allData={allData} />}  
                                    </Grid>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid container item direction="row" sm={8} md={8} lg={8} spacing={1}>
                                     <Grid item sm={12} md={12} lg={12}>
                                     {allData && <BandwidthReceive expandedState={vpnExpanded} allData={allData} />}   
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      {allData && <BandwidthTransmit expandedState={vpnExpanded} allData={allData} />}  
                                    </Grid> 
                                 </Grid> 
                                 <Grid item sm={4} md={4} lg={4}>
                                    <VPNstatusTable expandedState={vpnExpanded} />
                                </Grid> 
                             </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>   */}

                {/* {(deviceTypeName === "FIREWALL")  && 
                <Accordion expanded={sdwanExpanded} onChange={handleSdwanChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>SD - WAN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                  {allData && <VirtualWanStatus expandedState={sdwanExpanded} allData={allData} />}  
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                  {allData && <VirtualWanLatency expandedState={sdwanExpanded} allData={allData} />}  
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                  {allData && <VirtualWanSentReceivedTotal expandedState={sdwanExpanded} allData={allData} />}  
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <SdwanStatusTable expandedState={sdwanExpanded} allData={allData} />
                                </Grid>
                                <Grid container item direction="row" sm={8} md={8} lg={8} spacing={1}>
                                    <Grid item sm={6} md={6} lg={6}>
                                    {allData && <VirtualWanJitter expandedState={sdwanExpanded} allData={allData} />}    
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                        <VirtualWanActiveSessions expandedState={sdwanExpanded} allData={allData}/>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                      {allData && <VirtualWanPacketLoss expandedState={sdwanExpanded} allData={allData} />}  
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                      {allData && <AllBandwidths expandedState={sdwanExpanded} allData={allData}/>}  
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>} */}

                {(deviceTypeName === "FIREWALL") &&
                    <Accordion expanded={sdwanExpanded} onChange={handleSdwanChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 700 }}>SD-WAN</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <SdWanTable sdwanExpanded={sdwanExpanded} allData={allData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}

               
             {(deviceTypeName === "FIREWALL") &&
                <Accordion expanded={vdomExpanded} onChange={handleVdomChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>VDOM</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>

                                <Grid item sm={12} md={12} lg={12}>
                                    <VdomTab vdomExpanded={vdomExpanded} allData={allData} />
                                </Grid>
                            </Grid>


                        </Grid>
                    </AccordionDetails>
                </Accordion>}
            
    </Box>
) 
}     

        </>
    );
}

export default MainHealthDashboard;