import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Divider, Box, Card, CardContent, CardHeader, IconButton, List } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PublicIcon from '@mui/icons-material/Public';
import HomeIcon from '@mui/icons-material/Home';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import cardStyles from '../../styles/cardsstyles';
import Leaflet from "leaflet"
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, CircleMarker, Popup, useMapEvents } from "react-leaflet"
import { Icon } from 'leaflet';
import { UserContext } from '../../context/UserContext'

import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"
import markerRetina from "leaflet/dist/images/marker-icon-2x.png"

import { getMarkers } from "../../API/NetworkDevices/networkDevices";

import orangeMarker from '../../Assets/orange_marker.png';
import redMarker from '../../Assets/red_marker.png';
import greenMarker from '../../Assets/green_marker.png'
import grayMarker from '../../Assets/gray_marker.png'

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

const defaultIcon = new Leaflet.Icon({
  iconUrl: markerIcon,
  iconSize: [20, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
})

const greenIcon = new Leaflet.Icon({
  iconUrl: greenMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
})

const orangeIcon = new Leaflet.Icon({
  iconUrl: orangeMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const redIcon = new Leaflet.Icon({
  iconUrl: redMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const grayIcon = new Leaflet.Icon({
  iconUrl: grayMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: "10px",
  },
});

const DashboardMap = (props) => {
  const { setLocationId, selectedGroupIds, setSelectedGroupIds, setForceGroupDetailsRefresh, isFullScreen, setIsFullScreen, fullScreenChecking, setFullScreenChecking, selectedGroupNames, setSelectedGroupNames, locationData } = props;
  const [locationName, setLocationName] = useState("");
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const mapRef = useRef();
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({})
  const [status, setStatus] = useState(false);
  const [selectedBuId, setSelectedBuId] = useState(-1);
  const [mapDisply, setMapDisplay] = useState(0)
  const [zoom, setZoom] = useState(0)
  const [currentZoom, setCurrentZoom] = useState(0)
  const [subMarkers, setSubMarksers] = useState([]);
  
  const [mainBounds, setMainBounds] = useState(null)
  const [subBounds, setSubBounds] = useState(null);
  const [homeClicked, setHomeClicked] = useState(false);
  const [screenChange, setScreenChanges] = useState(0);
  const [showGraph, setShowGraph] = useState(true)

  const defaultCenter = { lat: 20, lng: 110.0 }; 
  const indiaBounds = Leaflet.latLngBounds([
    Leaflet.latLng(8.4, 75.0),   
    Leaflet.latLng(37.6, 100.0)  
  ]);

  const containerStyle = {
    width: isFullScreen ? '200%' : '100%',
    height: isFullScreen ? '680px' : '680px',
  }

  const _GetMarkers = () => {
    getMarkers(locationData, setMarkers, setCenter, setStatus, setMainBounds, setSubMarksers, customerId);
  }

  useEffect(() => {
    setZoom(5)
    setCurrentZoom(5)
    setMapDisplay(0)
    _GetMarkers();
  }, [locationData, status, isFullScreen]);

  useEffect(() => {
    setStatus(true)
  }, [localStorage.getItem("customerId")])

  useEffect(() => {
    setLocationName("");
  }, []);

  const markerDragEnd = (event, index) => {
  }

  return (
    <>
      <Card elevation={10} style={{ width: isFullScreen ? '200%' : '100%', position: 'relative', overflow: 'hidden' }}>
        <CardHeader
          sx={{
            py: 1,
            px: 1,
            backgroundColor: '#ffffff',
            zIndex: isFullScreen ? "1000" : 'inherit',
            transition: "width 0.1s, height 0.1s",
          }}
          color="text.error"
          title={
            <Typography variant='h6' component={'span'}>
              Sites-Geo Map
            </Typography>
          }
          avatar={<PublicIcon className={classes.boxTitleIcon} />}
          action={
            <IconButton onClick={() => setShowGraph(!showGraph)} className={classes.vendorChartActionArrowButton}>
              {showGraph ? <KeyboardArrowUpIcon sx={{ fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: '1.8rem' }} />}
            </IconButton>
          }
        />
        <Divider />
        {showGraph && status && mainBounds && (
          <CardContent sx={{ padding: '10px' , overflow: 'auto',scrollbarWidth:'thin'}}>
            <MapContainer
              key={`${screenChange}-${homeClicked}-${mainBounds?.LatLngBounds?._northEast?.LatLng?.lat}-${mainBounds?.
                LatLngBounds?._northEast?.LatLng?.lng}-${mainBounds?.
                  LatLngBounds?._southWest?.LatLng?.lat}-${mainBounds?.
                    LatLngBounds?._southWest?.LatLng?.lng}`}
              style={containerStyle}
              zoom={zoom} 
              center={defaultCenter}
              // bounds={mainBounds}
              bounds={indiaBounds}
              boundsOptions={{ padding: [0, 0] }}
              whenCreated={mapInstance => { mapRef.current = mapInstance; }}
              scrollWheelZoom={false}
              ref={mapRef}
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
              <MapClickHandler setHomeClicked={setHomeClicked} />
              {markers?.map((marker, index) => (
                marker.members && marker.position && (
                  <MainMarkerContent
                    key={index}
                    position={marker.position}
                    members={marker.members}
                    draggable={marker.draggable}
                    onDragEnd={event => markerDragEnd(event, index)}
                  />
                )
              ))}
            </MapContainer>
          </CardContent>
        )}
      </Card>
    </>

  );
};

function MapClickHandler({ setHomeClicked }) {
  const [clickTimeout, setClickTimeout] = useState(null);

  useMapEvents({
    dblclick: () => {
      if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
      }
    },
    click: () => {
      if (!clickTimeout) {
        const timeout = setTimeout(() => {
          setHomeClicked(homeClicked => !homeClicked);
          setClickTimeout(null);
        }, 300);
        setClickTimeout(timeout);
      }
    }
  });

  return null;
}

const MapContent = ({ onClick }) => {
  const map = useMapEvents({
    click: event => onClick(event)
  })
  return null;
}


const MainMarkerContent = (props) => {
  const markerRef = useRef();
  const navigate = useNavigate();
  const { locationId, position, members, draggable, onDragEnd } = props;

  const getMarkerColor = (members) => {
  
    if (members.some(member =>
      member.nodes.networkDeviceDownCount === member.nodes.networkDeviceCount &&
      member.nodes.networkDeviceUpCount === 0 &&
      member.nodes.networkDeviceCount !== 0
    )) {
      return '#FF5733'; // Red
    }
  
    if (members.some(member =>
      member.nodes.networkDeviceDownCount !== 0 &&
      member.nodes.networkDeviceUpCount < member.nodes.networkDeviceCount
    )) {
      return '#FFBF00'; // Yellow
    }
  
    if (members.some(member =>
      member.nodes.networkDeviceUpCount === member.nodes.networkDeviceCount &&
      member.nodes.networkDeviceDownCount === 0 &&
      member.nodes.networkDeviceUpCount !== 0 &&
      member.nodes.networkDeviceCount !== 0
    )) {
      return '#C7F6C7'; // Green
    }
  
    if (members.some(member =>
      member.nodes.networkDeviceDownCount === 0 &&
      member.nodes.networkDeviceUpCount === 0 &&
      member.nodes.networkDeviceCount === 0
    )) {
      return '#C0C0C0'; // Grey
    }
    };

  // Map the color to the correct Leaflet icon
  const getIconByColor = (color) => {
    switch (color) {
      case '#FF5733': return redIcon;
      case '#FFBF00': return orangeIcon;
      case '#C7F6C7': return greenIcon;
      case '#C0C0C0':return grayIcon;
      default: return defaultIcon;
    }
  };

  const markerColor = getMarkerColor(members);
  const markerIcon = getIconByColor(markerColor);

  const handleForwardClick = (loc) => {
    if (loc) {
      const locationId = loc?.location_id;
      navigate(`/dashboard/network-device?locationUuid=${locationId}`);
    }
  };

  const handleForwardUpDeviceClick = (loc) => {
    if (loc) {
      const locationId = loc?.location_id;
      navigate(`/dashboard/dashboard/device-up?locationUuid=${locationId}`);
    }
  };

  const handleForwardDownDeviceClick = (loc) => {
    if (loc) {
      const locationId = loc?.location_id;
      navigate(`/dashboard/dashboard/device-down?locationUuid=${locationId}`);
    }
  };

  const getCardBackgroundColor = (loc) => {
    if (loc.nodes.networkDeviceDownCount === loc.nodes.networkDeviceCount && loc.nodes.networkDeviceUpCount === 0 && loc.nodes.networkDeviceCount !== 0) {
      return '#FF5733';
    }
    if (loc.nodes.networkDeviceDownCount !== 0 && loc.nodes.networkDeviceUpCount < loc.nodes.networkDeviceCount) {
      return '#FFBF00';
    }
    if (loc.nodes.networkDeviceUpCount === loc.nodes.networkDeviceCount && loc.nodes.networkDeviceDownCount === 0 && loc.nodes.networkDeviceUpCount !== 0 && loc.nodes.networkDeviceCount !== 0) {
      return '#C7F6C7';
    }
    if (loc.nodes.networkDeviceDownCount === 0 && loc.nodes.networkDeviceUpCount === 0 && loc.nodes.networkDeviceCount === 0) {
      return '#C0C0C0';
    }
    return '#C0C0C0';
  };

  return (
    <Marker
      key={`marker-${members.map(member => member.location_id).join('-')}`}
      position={position}
      icon={markerIcon}  
      eventHandlers={{
        dragend: () => onDragEnd(markerRef.current.getLatLng()),
      }}
      ref={markerRef}
    >
      <Popup>
        <Paper sx={{
          maxHeight: '60vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '2px',
          }
        }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingRight: "10px", paddingLeft: "10px" }}>
            {members.map((loc, index) => (
              <Card key={index} sx={{ marginBottom: 2, backgroundColor: getCardBackgroundColor(loc) }}>
                <CardContent sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}>
                  <Typography variant="h6">
                    {loc.location_name}
                  </Typography>
                  <Divider />
                  <Typography variant="body2" color="textSecondary" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '8px'
                  }}>
                    Total Devices: {loc.nodes.networkDeviceCount}
                    <IconButton onClick={() => handleForwardClick(loc)} size="small" aria-label="open site">
                      <LaunchIcon />
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '8px'
                  }}>
                    Devices Up: {loc.nodes.networkDeviceUpCount}
                    <IconButton onClick={() => handleForwardUpDeviceClick(loc)} size="small" aria-label="open site">
                      <LaunchIcon />
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '8px'
                  }}>
                    Devices Down: {loc.nodes.networkDeviceDownCount}
                    <IconButton onClick={() => handleForwardDownDeviceClick(loc)} size="small" aria-label="open site">
                      <LaunchIcon />
                    </IconButton>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </List>
        </Paper>
      </Popup>
    </Marker>
  );
};

export default DashboardMap;

