/* eslint-disable */
import {
    Box,
    LinearProgress, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useSearchParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';
import { interfaceStatus } from '../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../context/UserContext';
import { useHealthContext } from '../userContextHealth';
import MainThroughputBandwidth from '../MainThroughputBandwidth/MainThroughputBandwidth';
import InterfaceSelector from '../InterfaceSelector';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 650,
    backgroundColor: 'yellow'
}));

// ---------------------------------------

const MainInterfaceTable = ({ interfaceExpanded }) => {
    const { healthDashState, getStartEnd } = useHealthContext();
    const { customerId } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const locationUuid = searchParams.get('locationUuid');
    const identifier = searchParams.get('identifier');
    
    const [statusData, setStatusData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInterface, setSelectedInterface] = useState(null);
    const [highlightedCell, setHighlightedCell] = useState(null);

    // ----------------------------------------------------------------------

    const isTablet = useMediaQuery('(max-width: 1020px)');
    const isDesktop = useMediaQuery('(min-width: 1440px)');
    const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

    const getFontSize = () => {
      if (isLargeDesktop) {
        return '20px';
      } 
      if (isDesktop) {
        return '16px';
      } 
      if (isTablet) {
        return '14px';
      } 
      return '12px';
    }

    const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 };

    // --------------------------------------------------------------------------------------------------------------------

   

    const columnHeaders = [
        {
            headerName: 'Interface',
            field: 'name',
            width: 500,
            sortable: false,
            filter: false,
            cellStyle: (params) => ({
                cursor: 'pointer', // Change cursor to pointer
                ...(params.node.rowIndex === highlightedCell ? { backgroundColor: '#90EE90', border: '2px solid #00acc1' } : responsiveCellStyle)
            }),
            cellRenderer: (params) => (
                <Tooltip title={`Interface: ${params.data.name}`} arrow>
                    <span>{params.data.name}</span>
                </Tooltip>
            ),
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 500,
            sortable: true,
            filter: false,
            cellStyle: (params) => ({
                cursor: 'pointer', // Change cursor to pointer
                ...(params.node.rowIndex === highlightedCell ? { backgroundColor: '#90EE90', border: '2px solid #00acc1' } : responsiveCellStyle)
            }),
            cellRenderer: (params) => (
                <Tooltip title={`Status: ${params.value === 0 ? 'Down' : 'Up'}`} arrow>
                    <span style={{ color: params.value === 0 ? 'red' : 'green' }}>
                        {params.value === 0 ? 'Down' : 'Up'}
                    </span>
                </Tooltip>
            ),
        },
        {
            headerName: 'Alias',
            field: 'alias',
            width: 500,
            sortable: false,
            filter: false,
            cellStyle: (params) => ({
                cursor: 'pointer', // Change cursor to pointer
                ...(params.node.rowIndex === highlightedCell ? { backgroundColor: '#90EE90', border: '2px solid #00acc1' } : responsiveCellStyle)
            }),
            cellRenderer: (params) => (
                <Tooltip title={`Alias: ${params.data.alias}`} arrow>
                    <span>{params.data.alias}</span>
                </Tooltip>
            ),
        },
    ];

    const fetchData = useCallback(() => {
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: identifier,
        };
        setLoading(true);
        
        interfaceStatus(customerId, payload)
            .then((res) => {
                setLoading(false);
                
                const mappedData = res.map(item => ({
                    name: item.interface,
                    alias: item.interface_alias,
                    status: item.int_status === "up(1)" ? 1 : 0,
                    parent: item.parent || 'N/A'
                }));
                
                const sortedStatusData = mappedData.sort((a, b) => a.status - b.status);
                setStatusData(sortedStatusData);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [interfaceExpanded, customerId, identifier, getStartEnd]);
// }, [interfaceExpanded]);

useEffect(() => {
    if (healthDashState.selectedDevice) {
        fetchData(); // Only call fetchData if the selectedDevice exists and changes
    }
}, [healthDashState.selectedDevice]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        filter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilter: false,
    }), []);

    const onCellClicked = (params) => {
        setSelectedInterface(params.data);
        setHighlightedCell(params.node.rowIndex); // Store the row index of the clicked cell
    };

    return (
        <>
        <Box sx={{display:'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
        {/* <InterfaceSelector interfaceExpanded={expandedState} setSelectedInterface={setSelectedInterface}   />  */}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>  
        <Paper variant="outlined" sx={{ flex: 1 }}>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <ChartWrapperStyle dir="ltr">
                <Box style={{ height: '650px', width: '100%' }} className="ag-theme-material">
                    <AgGridReact
                        rowData={statusData}
                        columnDefs={columnHeaders}
                        // onGridReady={fetchData}
                        defaultColDef={defaultColDef}
                        onCellClicked={onCellClicked}
                    />
                </Box>
            </ChartWrapperStyle>
        </Paper>
        {selectedInterface && (
            <Box sx={{ flex: 1 }}>
                <MainThroughputBandwidth
                    interfaceExpanded={interfaceExpanded}
                    selectedInterface={selectedInterface}
                    identifier={identifier}
                    locationUuid={locationUuid}
                />
            </Box>
        )}
    </Box>
        </>
    );
};

export default MainInterfaceTable;
