import { useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';

// layouts
import S3BrowserComponent from './components/CMDBBackups/S3BrowserComponent';
import ISPBad from './components/ISPManagementTable/ISPManagementDashboard/ISPBad';
import ISPBad1 from './components/ISPManagementTable/ISPManagementDashboard/ISPBad1/ISPBad1';
import ISPDown from './components/ISPManagementTable/ISPManagementDashboard/ISPDown';
import ISPDown1 from './components/ISPManagementTable/ISPManagementDashboard/ISPDown1/ISPDown1';
import ISPGood from './components/ISPManagementTable/ISPManagementDashboard/ISPGood';
import ISPGood1 from './components/ISPManagementTable/ISPManagementDashboard/ISPGood1/ISPGood1';
import SidewiseBad from './components/SidewiseComponents/Components/SidewiseBad';
import SidewiseDown from './components/SidewiseComponents/Components/SidewiseDown';
import SidewiseGood from './components/SidewiseComponents/Components/SidewiseGood';
import SidewiseTotalLinks from './components/SidewiseComponents/Components/SidewiseTotalLinks';
import ConfigBackupTableComponent from './components/CMDBBackups/ConfigBackupTableComponent';
import BackupFail from './components/SummaryComponents/Components/CardStatus/BackupFail/BackupFail';
import BackupSuccess from './components/SummaryComponents/Components/CardStatus/BackupSuccess/BackupSuccess';
import Alerts from './pages/Alerts';
import ConfigBackupEvents from './pages/ConfigBackupEvents';
import Configuration from './pages/Configuration';
import Credential from './pages/Credential';
import Dashboard from './pages/Dashboard';
import Dcagent from './pages/Dcagent';
import EmailConfig from './pages/EmailConfig';
import Endpoint from './pages/Endpoint';
import Global from './pages/Global';
import Inventory from './pages/Inventory';
import Ipam from './pages/Ipam';
import Locations from './pages/Locations';
import Members from './pages/Members';
import Network from './pages/Network';
import OnDemand from './pages/OnDemand';
import Overview from './pages/Overview';
import Roles from './pages/Roles';
import Schedulers from './pages/Schedulers';
import Sdvan from './pages/Sdvan';
import Sidewise from './pages/Sidewise';
import Sitewise from './pages/Sitewise';
import Smtp from './pages/Smtp';
import Summary from './pages/Summary';
import UnknownDevices from './pages/UnknownDevices';
import Zones from './pages/Zones';
import GroupedSummary from './pages/GroupedSummary';
import DevicewiseSummary from './pages/DevicewiseSummary';
import Health from './pages/Health';

// Login-Logout
import PageNotFound from './components/PageNotFound';
import ForgotPassword from './layouts/ForgotPassword';
import Login from './layouts/Login';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ResetPassword from './layouts/ResetPassword';
import Settings from './layouts/Settings';
import DeviceDown from './pages/DeviceDown';
import DeviceUp from './pages/DeviceUp';
import ConfigChangeBackupTable from './pages/ConfigBackupsTable';
import General from './pages/General';
import IgnoreRule from './pages/IgnoreRule'
import Inactive from './pages/Inactive';
import DeviceLifeCycle from './pages/system-configuration/DeviceLifeCycle';
import DeviceTypes from './pages/system-configuration/DeviceTypes';
import ConfigChangeEvents from './pages/ConfigChangeEvents';
import DeveloperConsole from './pages/system-configuration/DeveloperConsole';

// ----------------------------------------------------------------------
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'network-health', element: <Network /> },
        { path: 'locations', element: <Locations /> },
        { path: 'ondemand', element: <OnDemand /> },
        { 
          path: 'health', 
          element: <Health />,
          children:[
            { 
              path: ':locationUuid/:serialNumber',
              element:<Health /> 
            }
          ]
        },
        { path: 'network-device/inactive', element: <Inactive /> },
        { 
          path: 'network-device', 
          element: <Inventory />,
          children:[
            { path:':locationUuid', element: <Inventory /> }
          ] 
        },
        { 
          path: 'dashboard/device-up', 
          element: <DeviceUp />,
          children:[
            { path:':locationUuid', element: <DeviceUp /> }
          ] 
        },
        { 
          path: 'dashboard/device-down', 
          element: <DeviceDown />,
          children:[
            { path:':locationUuid', element: <DeviceDown /> }
          ]  
        },
        { path: 'global-map', element: <Global /> },
        { 
          path: 'sitewise-map', 
          element: <Sitewise />,
          children:[
            { path:':locationUuid', element: <Sitewise /> }
          ]
        },
        { path: 'email-config', element: <EmailConfig /> },
        { path: 'general', element: <General /> },
        { path: 'ignore-rule', element: <IgnoreRule /> },
        
        {
          path: 'ISP-sitewise',
          element: <Sidewise />,
          children: [
            { path: '', element: <SidewiseTotalLinks /> },
            { path: 'sidewise-good', element: <SidewiseGood /> },
            { path: 'sidewise-bad', element: <SidewiseBad /> },
            { path: 'sidewise-down', element: <SidewiseDown /> },
          ],
        },

        { path: 'endpoint', element: <Endpoint /> },
        { path: 'inventory', element: <Inventory /> },
        { path: 'ipam', element: <Ipam /> },
        { path: 'summary-events', element: <Summary /> },
        { path: 'grouped-summary', element: <GroupedSummary /> },
        { path: 'devicewise-summary', element: <DevicewiseSummary />},
        { path: 'smtp-imap', element: <Smtp /> },
        { path: 'sdwan', element: <Sdvan /> },
        {
          path: 'configuration',
          element: <Configuration />,
          children: [
            {
              path: ':customerName?/:locationName?/:deviceType?/:deviceIp?/:all?',
              element: <S3BrowserComponent />,
            },
          ],
        },
        { path: 'members', element: <Members /> },
        { path: 'roles', element: <Roles /> },
        { path: 'dc-agent', element: <Dcagent /> },
        { path: 'credential', element: <Credential /> },
        { path: 'device-up-down', element: <Alerts /> },
        { path: 'config-backup-events', element: <ConfigBackupEvents /> },
        { path: 'config-change-events', element: <ConfigChangeEvents /> },

        {
          path: 'config-change-backup-table',
          element: <ConfigChangeBackupTable />,
          children: [
            {
              path: ':customerName?/:locationName?/:deviceType?/:deviceIp?/:all?',
              element: <ConfigBackupTableComponent />,
            },
            {
              path: 'backup-fail/:date',
              // path: ':customerName?/:locationName?/:deviceType?/:deviceIp?/:all?',
              element: <BackupFail />,
            },
            {
              path: 'backup-success/:date',
              element: <BackupSuccess />,
            },
          ],
        },
        { path: 'unknown-devices', element: <UnknownDevices /> },
        { path: 'zones', element: <Zones /> },
        {
          path: 'isp-management',
          element: <Overview />,
          children: [
            { path: 'isp-good', element: <ISPGood /> },
            { path: 'isp-bad', element: <ISPBad /> },
            { path: '', element: <ISPDown /> },
          ],
        },
        { path: 'schedulers', element: <Schedulers /> },
        { path: 'ISPConfig', element: null },
        { path: 'settings', element: <Settings /> },
        { path: 'isp-management/isp-good/1', element: <ISPGood1 /> },
        { path: 'isp-management/isp-bad/1', element: <ISPBad1 /> },
        { path: 'isp-management/isp-down/1', element: <ISPDown1 /> },
      ],
    },
    {
      path: '/system-configuration',
      element: <DashboardLayout />,
      children: [
        { path: 'device-types', element: <DeviceTypes /> },
        { path: 'developer-console', element : <DeveloperConsole /> },
        { path: 'device-life-cycle', element: <DeviceLifeCycle /> },
      ],
    },
    {
      path: '/logout',
      element: <LogoOnlyLayout />,
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    { path: '*', element: <PageNotFound /> },
  ]);
}
