// import * as React from 'react';
// import DownloadIcon from '@mui/icons-material/Download';
// import {
//   Box,
//   Grid,
//   IconButton,
//   LinearProgress,
//   Tooltip,
//   Typography
// } from '@mui/material';
// import { saveAs } from 'file-saver';
// import { useSnackbar } from 'notistack';
// import { useEffect, useState } from 'react';
// import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
// import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
// import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';

// const newStyles = {
//   line: {
//     wordBreak: 'break-word',
//     padding: '10px 2px',
//     '&:hover': {
//       background: 'yellow',
//     },
//   },
//   diffContainer: {
//     borderStyle: 'solid',
//     borderColor: 'black',
//     borderWidth: '1px',
//     overflowX: 'auto',
//     display: 'block !important',
//     '& pre': { whiteSpace: 'pre-wrap' },
//   },
//   diffRemoved: {
//     overflowX: 'auto',
//     maxWidth: 300,
//   },
//   diffAdded: {
//     overflowX: 'auto',
//     maxWidth: 300,
//   },
// };

// export default function DiffDialog002(props) {
//   const { enqueueSnackbar } = useSnackbar();
//   const { data, cmdbGroupSummaryEvent, prevDetails } = props;
//   const [loading, setLoading] = useState(true);
//   const [openIgnore, setOpenIgnore] = useState(false);
//   const [gridApi, setGridApi] = useState(null);

 
//   const decodedPrevConfig = atob(prevDetails?.prev_config || '');
//   const decodedCurrConfig = atob(prevDetails?.curr_config || '');
//   const decodedCmdbGroupSummaryEvent = atob(prevDetails?.r_grp_smry_diff_html || '');

//   const downloadSrcTxtFile = async () => {
//     const blob = new Blob([decodedPrevConfig], { type: 'text/plain;charset=utf-8' });
//     saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_PrevConfig.txt`);
//   };

//   const downloadTarTxtFile = async () => {
//     const blob = new Blob([decodedCurrConfig], { type: 'text/plain;charset=utf-8' });
//     saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_CurrentConfig.txt`);
//   };

//   const handleLineNumberClick = (lineId, event) => {
//     if (lineId) {
//       setOpenIgnore(true);
//     }
//   };

//   useEffect(() => {
//     if (prevDetails?.prev_config || prevDetails?.curr_config) {
//       setLoading(false);
//     }
//   }, [prevDetails]);

//   const [decodedHtml, setDecodedHtml] = useState('');

//   useEffect(() => {
//     try {
//       const encodedHtml = prevDetails?.r_grp_smry_diff_html;
//       if (encodedHtml) {
//         const decodedString = atob(encodedHtml);
//         const decoded = decodeURIComponent(decodedString);
//         setDecodedHtml(generateHtmlDiff(decoded));
        
//       } else {
//         console.warn("No encoded HTML string provided.");
//       }
//     } catch (error) {
//       console.error("Error decoding Base64 string:", error);
//     }
//   }, [prevDetails]);

//   const generateHtmlDiff = (diffText) => {
//     let diffHtml = `
//       <style type="text/css">
//         .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
//         .diff div:hover {background-color:#ffc;}
//         .diff .control {background-color: #eaf2f5; color: #999999;}
//         .diff .insert {background-color: #ddffdd; color: #000000;}
//         .diff .delete {background-color: #ffdddd; color: #000000;}
//       </style>
//       <div class="diff">
//     `;

//     diffText.split('\n').forEach((line) => {
//       if (line.startsWith('@@')) {
//         diffHtml += `<div class="control">${line}</div>`;
//       } else if (line.startsWith('+++') || line.startsWith('+')) {
//         diffHtml += `<div class="insert">${line}</div>`;
//       } else if (line.startsWith('---') || line.startsWith('-')) {
//         diffHtml += `<div class="delete">${line}</div>`;
//       } else {
//         diffHtml += `<div>${line}</div>`;
//       }
//     });

//     diffHtml += '</div>';
//     return diffHtml;
//   };

//   return (
//     <>
//       {loading && <LinearProgress />}
//       <Box sx={{ p: 2, width: '100%' }}>
//         <SummaryDiff cmdbGroupSummaryEvent={decodedHtml} />

//         <Grid container>
//           <Grid item lg={6} md={6} sm={6}>
//             <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type}_${data?.g_created_at}_PrevConfig`}
//               <Tooltip title="Download file">
//                 <IconButton onClick={downloadSrcTxtFile} size="small">
//                   <DownloadIcon fontSize="inherit" />
//                 </IconButton>
//               </Tooltip>
//             </Typography>
//           </Grid>

//           <Grid item lg={6} md={6} sm={6}>
//             <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type}_${data?.g_created_at}_CurrentConfig`}
//               <Tooltip title="Download file">
//                 <IconButton onClick={downloadTarTxtFile} size="small">
//                   <DownloadIcon fontSize="inherit" />
//                 </IconButton>
//               </Tooltip>
//             </Typography>
//           </Grid>
//         </Grid>

//         <Box>
//           {decodedPrevConfig || decodedCurrConfig ? (
//             <ReactDiffViewer
//               compareMethod={DiffMethod.WORDS}
//               oldValue={decodedPrevConfig}
//               newValue={decodedCurrConfig}
//               styles={newStyles}
//               leftTitle={'Previous configuration'}
//               rightTitle={'Current configuration'}
//               onLineNumberClick={handleLineNumberClick}
//               splitView
//             />
//           ) : (
//             ''
//           )}
//           {openIgnore && (
//             <IgnoreLineDialog
//               gridApi={gridApi}
//               openDialog={openIgnore}
//               handleCloses={() => setOpenIgnore(false)}
//               data={data}
//             />
//           )}
//         </Box>
//       </Box>
//     </>
//   );
// }

import * as React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';

const newStyles = {
  line: {
    wordBreak: 'break-word',
    padding: '10px 2px',
    '&:hover': {
      background: 'yellow',
    },
  },
  diffContainer: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    overflowX: 'auto',
    display: 'block !important',
    '& pre': { whiteSpace: 'pre-wrap' },
  },
  diffRemoved: {
    overflowX: 'auto',
    maxWidth: 300,
  },
  diffAdded: {
    overflowX: 'auto',
    maxWidth: 300,
  },
};

export default function DiffDialog002(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, cmdbGroupSummaryEvent, prevDetails } = props;
  const [loading, setLoading] = useState(true);
  const [openIgnore, setOpenIgnore] = useState(false);
  const [gridApi, setGridApi] = useState(null);

 
  const decodedPrevConfig = atob(prevDetails?.prev_config || '');
  const decodedCurrConfig = atob(prevDetails?.curr_config || '');
  const decodedCmdbGroupSummaryEvent = atob(prevDetails?.r_grp_smry_diff_html || '');

  const downloadSrcTxtFile = async () => {
    const blob = new Blob([decodedPrevConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_PrevConfig.txt`);
  };

  const downloadTarTxtFile = async () => {
    const blob = new Blob([decodedCurrConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}__${data?.r_dvc_unique_identifier}_CurrentConfig.txt`);
  };

  const handleLineNumberClick = (lineId, event) => {
    if (lineId) {
      setOpenIgnore(true);
    }
  };

  useEffect(() => {
    if (prevDetails?.prev_config || prevDetails?.curr_config) {
      setLoading(false);
    }
  }, [prevDetails]);

  const [decodedHtml, setDecodedHtml] = useState('');

  // useEffect(() => {
  //   try {
  //     const encodedHtml = prevDetails?.r_grp_smry_diff_html;
  //     if (encodedHtml) {
  //       const decodedString = atob(encodedHtml);
  //       const decoded = decodeURIComponent(decodedString);
  //       setDecodedHtml(generateHtmlDiff(decoded));
        
  //     } else {
  //       console.warn("No encoded HTML string provided.");
  //     }
  //   } catch (error) {
  //     console.error("Error decoding Base64 string:", error);
  //   }
  // }, [prevDetails]);

  useEffect(() => {
    try {
      const encodedHtml = prevDetails?.r_grp_smry_diff_html;
      if (encodedHtml) {
        const decodedString = atob(encodedHtml);
        try {
          const uriDecodedString = decodeURIComponent(decodedString);
          setDecodedHtml(generateHtmlDiff(uriDecodedString));
        } catch (uriError) {
          console.warn("URI decoding not needed or failed:", uriError);
          setDecodedHtml(generateHtmlDiff(decodedString)); 
        }
      } else {
        console.warn("No encoded HTML string provided.");
      }
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
    }
  }, [prevDetails]);
  

  const generateHtmlDiff = (diffText) => {
    let diffHtml = `
      <style type="text/css">
        .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
        .diff div:hover {background-color:#ffc;}
        .diff .control {background-color: #eaf2f5; color: #999999;}
        .diff .insert {background-color: #ddffdd; color: #000000;}
        .diff .delete {background-color: #ffdddd; color: #000000;}
      </style>
      <div class="diff">
    `;

    diffText.split('\n').forEach((line) => {
      if (line.startsWith('@@')) {
        diffHtml += `<div class="control">${line}</div>`;
      } else if (line.startsWith('+++') || line.startsWith('+')) {
        diffHtml += `<div class="insert">${line}</div>`;
      } else if (line.startsWith('---') || line.startsWith('-')) {
        diffHtml += `<div class="delete">${line}</div>`;
      } else {
        diffHtml += `<div>${line}</div>`;
      }
    });

    diffHtml += '</div>';
    return diffHtml;
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ p: 2, width: '100%' }}>
        <SummaryDiff cmdbGroupSummaryEvent={decodedHtml} />

        <Grid container>
          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_PrevConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadSrcTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_CurrentConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadTarTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        <Box>
          {decodedPrevConfig || decodedCurrConfig ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              oldValue={decodedPrevConfig}
              newValue={decodedCurrConfig}
              styles={newStyles}
              leftTitle={'Previous configuration'}
              rightTitle={'Current configuration'}
              onLineNumberClick={handleLineNumberClick}
              splitView
            />
          ) : (
            ''
          )}
          {openIgnore && (
            <IgnoreLineDialog
              gridApi={gridApi}
              openDialog={openIgnore}
              handleCloses={() => setOpenIgnore(false)}
              data={data}
            />
          )}
        </Box>
      </Box>
    </>
  );
}