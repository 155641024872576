import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MainChart = ({ seriesData, xAxisData }) => {

    // const formatBytes = (bytes) => {
    //     if (bytes === 0) return '0 Bytes';
    //     const k = 1024;
    //     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    //     const i = Math.floor(Math.log(bytes) / Math.log(k));
    //     return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    // };

    const formatBytes = (octets) => {
        if (octets === 0) return '0 Bits';
        const bits = octets * 8; // Convert octets to bits
        const k = 1024;
        const sizes = ['Bits', 'Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bits) / Math.log(k));
        return parseFloat((bits / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: true,
            },
        },
        
        xaxis: {
            categories: xAxisData,
            title: {
                text: 'Time',
            },
            axisTicks: {
                show: true,
            },
            crosshairs: {
                show: true,
                position: 'back',
            },
        },
        title: {
            text: 'Interface Metrics',
            align: 'left',
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2, 5], // Different thickness for each series: InOctet, OutOctet, Status (thicker for Status)
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter(val, { seriesIndex }) {
                    if (seriesIndex === 2) {
                        return val === 1 ? 'Up' : 'Down';
                    }
                    return formatBytes(val);
                },
            },
        },
       
        yaxis: [
            {
                title: {
                    text: 'InOctet (Bytes)',
                },
                min: 0,
                max: Math.max(...seriesData.find(s => s.name === 'InOctet')?.data) * 1.2,
                labels: {
                    formatter: (val) => formatBytes(val),
                },
            },
            {
                opposite: true,
                title: {
                    text: 'OutOctet (Bytes)',
                },
                min: 0,
                max: Math.max(...seriesData.find(s => s.name === 'OutOctet')?.data) * 1.2,
                labels: {
                    formatter: (val) => formatBytes(val),
                },
            },
            {
                opposite: true,
                title: {
                    text: 'Status',
                },
                min: 0,
                max: 1, // Only display values 0 and 1 for Down and Up
                tickAmount: 1, // Display only 0 (Down) and 1 (Up)
                forceNiceScale: true,
                labels: {
                    formatter: (val) => (val === 1 ? 'Up' : 'Down'),
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                },
            },
        ],
        
        
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
        // Adjusting the colors for better visibility
        colors: ['#FF5733', '#1E90FF', '#28A745'], // Brighter, more distinct colors for each series: InOctet, OutOctet, and Status
        legend: {
            show: true,
            onItemClick: {
                toggleDataSeries: true,
            },
        },
    };

    return (
        <div>
            {seriesData?.length > 0 && xAxisData?.length > 0 && (
                <ReactApexChart series={seriesData} options={chartOptions} type="area" height={590} />
            )}
        </div>
    );
};

export default MainChart;
