import * as React from 'react';
import {
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import SourceIcon from '@mui/icons-material/Source';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandAllIcon from '@mui/icons-material/UnfoldMore';
import CollapseAllIcon from '@mui/icons-material/UnfoldLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigDialog from './ConfigDialog';
import { UserContext } from '../../../context/UserContext';
import { getEventsDifferenceData, approveEventChange, unApproveEventChange } from '../../../API/S3/ConfigEvents';

const decodeBase64 = (str) => {
  try {
    return atob(str);
  } catch (error) {
    console.error('Base64 decoding failed', error);
    return str;
  }
};

const generateHtmlDiff = (diffText) => {
  let diffHtml = `
    <style type="text/css">
      .diff {border: 1px solid #cccccc; background: #f8f8f8; font-family: monospace; font-size: 12px; line-height: 1.4; white-space: pre-wrap; word-wrap: break-word;}
      .diff div:hover {background-color:#ffc;}
      .diff .control {background-color: #eaf2f5; color: #999999;}
      .diff .insert {background-color: #ddffdd; color: #000000;}
      .diff .delete {background-color: #ffdddd; color: #000000;}
    </style>
    <div class="diff">
  `;

  diffText.split('\n').forEach((line) => {
    if (line.startsWith('@@')) {
      diffHtml += `<div class="control">${line}</div>`;
    } else if (line.startsWith('+++') || line.startsWith('+')) {
      diffHtml += `<div class="insert">${line}</div>`;
    } else if (line.startsWith('---') || line.startsWith('-')) {
      diffHtml += `<div class="delete">${line}</div>`;
    } else {
      diffHtml += `<div>${line}</div>`;
    }
  });

  diffHtml += '</div>';
  return diffHtml;
};

const ConfigRenderer = (props) => {
  const { data } = props;
  const uniqueIdentifier = data?.r_dvc_unique_identifier;
  const navigate = useNavigate();
  const { customerId } = useContext(UserContext);

  // console.log("12345",eventId);

  const [openChonkyDialog, setOpenChonkyDialog] = useState(false);
  const [differenceData, setDifferenceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [error, setError] = useState(null);
  const [openFileDiffDialog, setOpenFileDiffDialog] = useState(false);
  const [decodedDiffs, setDecodedDiffs] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [approveError, setApproveError] = useState([]);
  const [unApproveError, setUnApproveError] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  // const [deviceData , setDeviceData] = useState([])
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpenChonkyDialog(true);
  };

  const payload = {
    startRow: 0,
    endRow: 200,
  };

  const handleOpenFileDiffDialog = () => {
    setOpenFileDiffDialog(true);
    setLoading(true);
    setError(null);

    getEventsDifferenceData(customerId, uniqueIdentifier, payload)
      .then((response) => {
        const deviceData = response?.data?.device_data || [];
        // setDeviceData(deviceData);
        const totalItems = response?.data?.total || 0;
        console.log('123456', deviceData);

        let lastRow = -1;
        if (totalItems <= payload.endRow) {
          lastRow = totalItems;
        }
        setTotalItems(totalItems);

        if (totalItems === 0) {
          setError('No data available.');
        } else {
          setDifferenceData(deviceData);
        }

        const decodedDiffs = deviceData.map((item) => {
          if (!item.r_file_difference) {
            return 'No file difference available';
          }

          const decoded = decodeBase64(item.r_file_difference);
          return generateHtmlDiff(decoded);
        });

        setDecodedDiffs(decodedDiffs);
        setLoading(false);
        setExpanded(deviceData.map((_, idx) => idx));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      });
  };

  const handleCloseFileDiffDialog = () => {
    setOpenFileDiffDialog(false);
    setApproveError([]);
    setUnApproveError([]);
    setSuccessMessage([]);
  };

  const [allExpanded, setAllExpanded] = useState(true);

  const handleToggleAll = () => {
    if (allExpanded) {
      handleCollapseAll();
    } else {
      handleExpandAll();
    }
    setAllExpanded(!allExpanded);
  };

  const handleExpandAll = () => {
    setExpanded(differenceData.map((_, index) => index));
  };

  const handleCollapseAll = () => {
    setExpanded([]); // Collapse all
  };

  const handleAccordionToggle = (index) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(index) ? prevExpanded.filter((item) => item !== index) : [...prevExpanded, index]
    );
  };

  const handleApprove = (eventId, index) => {
    setApproveError([]);
    setSuccessMessage([]);
    approveEventChange(customerId, eventId)
      .then(() => {
        const newSuccessMessage = [...successMessage];
        newSuccessMessage[index] = 'Event approved successfully!';
        setSuccessMessage(newSuccessMessage);
      })
      .catch((error) => {
        console.error('Error approving event:', error);
        const newApproveError = [...approveError];
        newApproveError[index] = 'Error approving event.';
        setApproveError(newApproveError);
      });
  };

  const handleUnApprove = (eventId, index) => {
    setUnApproveError([]);
    setSuccessMessage([]);
    unApproveEventChange(customerId, eventId)
      .then(() => {
        const newSuccessMessage = [...successMessage];
        newSuccessMessage[index] = 'Event un-approved successfully!';
        setSuccessMessage(newSuccessMessage);
      })
      .catch((error) => {
        console.error('Error Un-approving event:', error);
        const newUnApproveError = [...unApproveError];
        newUnApproveError[index] = 'Error un-approving event.';
        setUnApproveError(newUnApproveError);
      });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <IconButton onClick={handleClickOpen}>
        <SourceIcon />
      </IconButton>

      {openChonkyDialog && (
        <ConfigDialog
          openDialog={openChonkyDialog}
          data={data}
          handleClose={() => {
            navigate('/dashboard/config-change-backup-table');
            setOpenChonkyDialog(false);
          }}
        />
      )}

      <IconButton onClick={handleOpenFileDiffDialog} sx={{ marginLeft: '10px' }}>
        <VisibilityIcon />
      </IconButton>

      <Dialog
        open={openFileDiffDialog}
        onClose={handleCloseFileDiffDialog}
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: { height: '80%', maxHeight: '80vh' },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bgcolor: 'grey.200',
              p: 12,
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '12px',
              marginBottom: '8px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {' '}
              <Typography variant="subtitle1" color="black" sx={{ fontWeight: 'bold', bgcolor: 'grey.200' }}>
                Total events: {totalItems}
              </Typography>
              <IconButton onClick={handleToggleAll}>{allExpanded ? <CollapseAllIcon /> : <ExpandAllIcon />}</IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%', 
            overflowY: 'auto',
          }}
        >
          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : differenceData && Array.isArray(differenceData) && differenceData.length > 0 ? (
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              {differenceData.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    bgcolor: 'grey.200',
                    padding: '0.1px',
                    marginBottom: '14px',
                    gap: 2,
                    flexGrow: 1,
                  }}
                >
                  <Accordion
                    expanded={expanded.includes(index)}
                    onChange={() => handleAccordionToggle(index)}
                    sx={{ flexGrow: 1 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-content-${index}`}
                      id={`panel-header-${index}`}
                      sx={{ bgcolor: 'grey.200' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          bgcolor: 'grey.200',
                          width: '100%',
                          borderRadius: '4px',
                          padding: '0.1px',
                          marginBottom: '10px',
                          gap: 2,
                        }}
                      >
                        <Typography>{item.g_updated_at}</Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '4px',
                            bgcolor: 'grey.200',
                            padding: '0.1px',
                            marginBottom: '10px',
                            gap: 1,
                          }}
                        >
                          <IconButton
                            color="success"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleApprove(item.cmdb_event_id, index);
                            }}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUnApprove(item.cmdb_event_id, index);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        flexGrow: 1,
                        height: '100%', 
                        maxHeight: '200px', 
                        minHeight: '100px', 
                        overflowY: 'auto',
                      }}
                    >
                      <Box dangerouslySetInnerHTML={{ __html: decodedDiffs[index] }} />
                      {/* variant="subtitle1" color="black" sx={{ fontWeight: 'bold', bgcolor: 'grey.200' }} */}
                      {approveError[index] && <Typography color="error" sx={{ marginTop: '16px' }}>{approveError[index]}</Typography>}
                      {unApproveError[index] && <Typography color="error" sx={{ marginTop: '16px' }}>{unApproveError[index]}</Typography>}
                      {successMessage[index] && <Typography color="black" sx={{ fontWeight: 'bold', marginTop: '16px' }}>{successMessage[index]}</Typography>}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No differences available</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Box sx={{ display: 'flex' }}>
            <Button
              onClick={handleCloseFileDiffDialog}
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkblue',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ConfigRenderer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConfigRenderer;
