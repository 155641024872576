

// import { Grid, Paper, Tabs, Tab, CircularProgress, Typography, LinearProgress } from '@mui/material';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { availabilityStatus } from 'src/API/NetworkDevices/health';
// import { useHealthContext } from '../../../userContextHealth';
// import AvailabilityChart from './AvailabilityChart';
// import { useSearchParams } from 'react-router-dom';

// const AvailabilityPage = ({ availabilityExpanded, all1Data }) => {
//   const { customerId } = useContext(UserContext);
//   const { healthDashState, getStartEnd } = useHealthContext();
//   const [searchParams] = useSearchParams();
//   const locationUuid = searchParams.get('locationUuid');
//   const identifier = searchParams.get('identifier');

//   const [value, setValue] = useState(0); // State to manage tabs
//   const [seriesData, setSeriesData] = useState([]);
//   const [xAxisData, setXAxisData] = useState([]);
//   const [rawData, setRawData] = useState({}); // Store raw data for all tabs
//   const [chartLoading, setChartLoading] = useState(false);
//   const [pageLoading, setPageLoading] = useState(true); // Top-level loading state

//   const handleChange = (event, newValue) => {
//     setValue(newValue);

//     // Update the series data based on the selected tab
//     switch (newValue) {
//       case 0: // Latency
//         setSeriesData([{ name: 'Latency', data: rawData?.latencyData || [] }]);
//         break;
//       case 1: // Jitter
//         setSeriesData([{ name: 'Jitter', data: rawData?.jitterData || [] }]);
//         break;
//       case 2: // Packet Loss
//         setSeriesData([{ name: 'Packet Loss', data: (rawData?.packetlossData || []).map(val => val.toFixed(0)) }]);
//         break;
//       case 3: // Up/Down
//         setSeriesData([{ name: 'Up/Down', data: rawData?.upDownData || [] }]);
//         break;
//       default:
//         break;
//     }
//   };

//   const fetchData = async () => {
//     const data = getStartEnd();
//     const payload = {
//       start: data.start / 1000,
//       end: data.end / 1000,
//       device_type: identifier,
//     };

//     try {
//       setPageLoading(true); // Start top-level loading
//       setChartLoading(true); // Start chart-specific loading
//       const res = await availabilityStatus(customerId, payload);

//       const latencyData = res?.latencies.map(Number);
//       const jitterData = res?.jitterss.map(Number);
//       const packetlossData = res?.packet_losses.map(Number);
//       const upDownData = res?.statuses.map(status => (status === 1 ? 1 : 0));
//       const formattedXAxisData = res?.timestamps.map(timestamp => {
//         const date = new Date(timestamp * 1000);
//         const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//         const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//         return `${timeString}\n${dateString}`;
//       });

//       setRawData({
//         latencyData,
//         jitterData,
//         packetlossData,
//         upDownData,
//       });

//       setXAxisData(formattedXAxisData);

//       // Set initial series data based on the default tab (Latency)
//       setSeriesData([{ name: 'Latency', data: latencyData }]);
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setPageLoading(false); // Stop top-level loading
//       setChartLoading(false); // Stop chart-specific loading
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [healthDashState.timeRange, healthDashState.selectedDevice]);

//   return (
//     <>
//       {pageLoading && <LinearProgress />} 
//       <Paper sx={{ padding: '10px' }} variant="outlined">
//         <Grid container spacing={1}>
//           <Grid item lg={12} md={12} sm={12}>
//             <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
//               <Tab label="Latency" />
//               <Tab label="Jitter" />
//               <Tab label="Packet Loss" />
//               <Tab label="Up/Down" />
//             </Tabs>
//           </Grid>
//           <Grid item lg={12} md={12} sm={12}>
//             {chartLoading ? (
//               <CircularProgress /> 
//             ) : seriesData.length > 0 && xAxisData.length > 0 ? (
//               <AvailabilityChart seriesData={seriesData} xAxisData={xAxisData} tabIndex={value} />
//             ) : (
//               <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
//                 No data at the moment
//               </Typography>
//             )}
//           </Grid>
//         </Grid>
//       </Paper>
//     </>
//   );
// };

// export default AvailabilityPage;

// import { Grid, Paper, Tabs, Tab, CircularProgress, Typography, LinearProgress } from '@mui/material';
// import { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../../../../context/UserContext';
// import { availabilityStatus } from 'src/API/NetworkDevices/health';
// import { useHealthContext } from '../../../userContextHealth';
// import AvailabilityChart from './AvailabilityChart';
// import { useSearchParams } from 'react-router-dom';

// const AvailabilityPage = ({ availabilityExpanded, all1Data }) => {
//   const { customerId } = useContext(UserContext);
//   const { healthDashState, getStartEnd } = useHealthContext();
//   const [searchParams] = useSearchParams();
//   const locationUuid = searchParams.get('locationUuid');
//   const identifier = searchParams.get('identifier');

//   const [value, setValue] = useState(0); // State to manage tabs
//   const [seriesData, setSeriesData] = useState([]);
//   const [xAxisData, setXAxisData] = useState([]);
//   const [rawData, setRawData] = useState({}); // Store raw data for all tabs
//   const [chartLoading, setChartLoading] = useState(false);
//   const [pageLoading, setPageLoading] = useState(true); // Top-level loading state

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const updateSeriesData = (tabIndex) => {
//     switch (tabIndex) {
//       case 0: // Latency
//         setSeriesData([{ name: 'Latency', data: rawData.latencyData || [] }]);
//         break;
//       case 1: // Jitter
//         setSeriesData([{ name: 'Jitter', data: rawData.jitterData || [] }]);
//         break;
//       case 2: // Packet Loss
//         setSeriesData([{ name: 'Packet Loss', data: (rawData.packetlossData || []).map(val => val.toFixed(0)) }]);
//         break;
//       case 3: // Up/Down
//         setSeriesData([{ name: 'Up/Down', data: rawData.upDownData || [] }]);
//         break;
//       default:
//         break;
//     }
//   };

//   const fetchData = async () => {
//     const data = getStartEnd();
//     const payload = {
//       start: data.start / 1000,
//       end: data.end / 1000,
//       device_type: identifier,
//     };

//     try {
//       setPageLoading(true); // Start top-level loading
//       setChartLoading(true); // Start chart-specific loading
//       const res = await availabilityStatus(customerId, payload);

//       const latencyData = res?.latencies.map(Number);
//       const jitterData = res?.jitterss.map(Number);
//       const packetlossData = res?.packet_losses.map(Number);
//       const upDownData = res?.statuses.map(status => (status === 1 ? 1 : 0));
//       const formattedXAxisData = res?.timestamps.map(timestamp => {
//         const date = new Date(timestamp * 1000);
//         const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//         const dateString = date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
//         return `${timeString}\n${dateString}`;
//       });

//       setRawData({
//         latencyData,
//         jitterData,
//         packetlossData,
//         upDownData,
//       });

//       setXAxisData(formattedXAxisData);

//       // Set initial series data based on the current tab (default is Latency)
//       updateSeriesData(value);
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setPageLoading(false); // Stop top-level loading
//       setChartLoading(false); // Stop chart-specific loading
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [healthDashState.timeRange, healthDashState.selectedDevice]);

//   // Whenever rawData or the tab value changes, update the chart series data
//   useEffect(() => {
//     updateSeriesData(value);
//   }, [value, rawData]);

//   return (
//     <>
//       {pageLoading && <LinearProgress />} 
//       <Paper sx={{ padding: '10px' }} variant="outlined">
//         <Grid container spacing={1}>
//           <Grid item lg={12} md={12} sm={12}>
//             <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
//               <Tab label="Latency" />
//               <Tab label="Jitter" />
//               <Tab label="Packet Loss" />
//               <Tab label="Up/Down" />
//             </Tabs>
//           </Grid>
//           <Grid item lg={12} md={12} sm={12}>
//             {chartLoading ? (
//               <CircularProgress /> 
//             ) : seriesData.length > 0 && xAxisData.length > 0 ? (
//               <AvailabilityChart seriesData={seriesData} xAxisData={xAxisData} tabIndex={value} />
//             ) : (
//               <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
//                 No data at the moment
//               </Typography>
//             )}
//           </Grid>
//         </Grid>
//       </Paper>
//     </>
//   );
// };

// export default AvailabilityPage;

import {
  Grid,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { availabilityStatus } from 'src/API/NetworkDevices/health';
import { useHealthContext } from '../../../userContextHealth';
import AvailabilityChart from './AvailabilityChart';
import { useSearchParams } from 'react-router-dom';

const AvailabilityPage = ({ availabilityExpanded, all1Data }) => {
  const { customerId } = useContext(UserContext);
  const { healthDashState, getStartEnd } = useHealthContext();
  const [searchParams] = useSearchParams();
  const locationUuid = searchParams.get('locationUuid');
  const identifier = searchParams.get('identifier');

  const [value, setValue] = useState(0); // State to manage tabs
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [rawData, setRawData] = useState({}); // Store raw data for all tabs
  const [chartLoading, setChartLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true); // Top-level loading state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateSeriesData = (tabIndex) => {
    switch (tabIndex) {
      case 0: // Latency
        setSeriesData([
          { name: 'Latency', data: rawData.latencyData || [] },
        ]);
        break;
      case 1: // Jitter
        setSeriesData([
          { name: 'Jitter', data: rawData.jitterData || [] },
        ]);
        break;
      case 2: // Packet Loss
        setSeriesData([
          {
            name: 'Packet Loss',
            data: (rawData.packetlossData || []).map((val) =>
              parseFloat(val.toFixed(0))
            ),
          },
        ]);
        break;
      case 3: // Up/Down
        setSeriesData([
          { name: 'Up/Down', data: rawData.upDownData || [] },
        ]);
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    const data = getStartEnd();
    const payload = {
      start: data.start / 1000, // Assuming API expects seconds
      end: data.end / 1000,
      device_type: identifier,
    };

    try {
      setPageLoading(true); // Start top-level loading
      setChartLoading(true); // Start chart-specific loading
      const res = await availabilityStatus(customerId, payload);

      const latencyData = res?.latencies.map(Number);
      const jitterData = res?.jitterss.map(Number);
      const packetlossData = res?.packet_losses.map(Number);
      const upDownData = res?.statuses.map((status) =>
        status === 1 ? 1 : 0
      );
      const formattedXAxisData = res?.timestamps.map(
        (timestamp) => timestamp * 1000 // Convert to milliseconds
      );

      setRawData({
        latencyData,
        jitterData,
        packetlossData,
        upDownData,
      });

      setXAxisData(formattedXAxisData);

      // Set initial series data based on the current tab (default is Latency)
      updateSeriesData(value);
    } catch (err) {
      console.error(err);
    } finally {
      setPageLoading(false); // Stop top-level loading
      setChartLoading(false); // Stop chart-specific loading
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthDashState.timeRange, healthDashState.selectedDevice]);

  // Whenever rawData or the tab value changes, update the chart series data
  useEffect(() => {
    updateSeriesData(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, rawData]);

  return (
    <>
      {pageLoading && <LinearProgress />} 
      <Paper sx={{ padding: '10px' }} variant="outlined">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Latency" />
              <Tab label="Jitter" />
              <Tab label="Packet Loss" />
              <Tab label="Up/Down" />
            </Tabs>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            {chartLoading ? (
              <CircularProgress /> 
            ) : seriesData.length > 0 && xAxisData.length > 0 ? (
              <AvailabilityChart
                seriesData={seriesData}
                xAxisData={xAxisData}
                tabIndex={value}
              />
            ) : (
              <Typography
                variant="h6"
                align="center"
                sx={{ marginTop: 2 }}
              >
                No data at the moment
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AvailabilityPage;
